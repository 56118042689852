const getDefaultState = () => {
    return {
        list: null,
        data: {},
        count: 0,
        originFilter: {},
    }
}
export default {
    namespaced: true,
    state: getDefaultState(),
    getters: {
        totalPages: state => {
            const limit = state.originFilter?.limit || 20
            const count = state.count || 0
            return Math.ceil(count / limit)
        },
    },
    mutations: {
        resetState(state) {
            Object.assign(state, getDefaultState())
        },
        setListResponse(state, data) {
            state.list = data.users
            state.count = data.totalCount
        },
        setUserResponse(state, data) {
            state.data = data.user
        },
        setOriginFilter(state, filter) {
            state.originFilter = JSON.parse(JSON.stringify(filter))
        },
    },
    actions: {
        async getUserList({ commit }, payload) {
            let response = null

            try {
                response = await this._vm.get(
                    `${this._vm.SPARK_ONE_CREW_API_URL}users`
                    , payload
                )
                commit('setListResponse', response.data)
                commit('setOriginFilter', payload)
            } catch (error) {
                if (error.response) {
                    response = error
                } else {
                    throw error
                }
            }

            return response
        },
        async getUserInfo({ commit }, payload) {
            let response = null

            try {
                response = await this._vm.get(
                    `${this._vm.SPARK_ONE_CREW_API_URL}user/${payload.userId}`
                )
                commit('setUserResponse', response.data)
                commit('setOriginFilter', payload)
            } catch (error) {
                if (error.response) {
                    response = error
                } else {
                    throw error
                }
            }

            return response
        },
        async getUser(context, payload) {
            let response = null

            try {
                response = await this._vm.get(
                    `${this._vm.SPARK_ONE_CREW_API_URL}user?loginId=${payload.loginId}`
                )
            } catch (error) {
                if (error.response) {
                    response = error
                } else {
                    throw error
                }
            }

            return response
        },
        async updateUserStatus(_context, payload = {}) {
            let response = null
            try {
                const { statusType, changeReason } = payload
                response = await this._vm.put(
                    `${this._vm.SPARK_ONE_CREW_API_URL}user/${payload.userId}/status`,
                    { statusType, changeReason }
                )
            } catch (error) {
                if (error.response) {
                    response = error
                } else {
                    throw error
                }
            }
            return response
        },
        async getUserExcelList(_context, payload) {
            let response = null
            try {
                response = await this._vm.get(
                    `${this._vm.SPARK_ONE_CREW_API_URL}users/excel`,
                    payload,
                )
            } catch (error) {
                if (error.response) {
                    response = error
                } else {
                    throw error
                }
            }
            return response
        },  
    },
}
