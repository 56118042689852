const getDefaultState = () => {
  return {
    list: null,
    originFilter: {},
  }
}
export default {
  namespaced: true,
  state: getDefaultState(),
  getters: {
    // totalPages: state => {
    //   const limit = state.originFilter?.limit || 20
    //   const count = state.count || 0
    //   return Math.ceil(count / limit)
    // },
  },
  mutations: {
    resetState (state) {
      Object.assign(state, getDefaultState())
    },
    setResponse (state, data) {
      //   state.list = data.billingList
      //   state.summarylist = data.billingListSummaryAmount
      //   state.count = data.totalCount
    },
    setOriginFilter (state, filter) {
      state.originFilter = JSON.parse(JSON.stringify(filter))
    },
  },
  actions: {

    // 엑셀 리스트 불러오기
    async getMultiPrintMonthly (_context, payload) {
      let response = null
      try {
        response = await this._vm.get(
                    `${this._vm.SPARK_ONE_CREW_API_URL}account/usage/printer-excel`,
                    payload,
        )
      } catch (error) {
        if (error.response) {
          response = error
        } else {
          throw error
        }
      }
      return response
    },

  },
}
