export default {
  namespaced: true,
  state: {
    info: {},
    list: [],
    count: 0,
    totalPage: 0,
  },
  getters: {

  },
  mutations: {
    resetState (state) {
      Object.assign(state.count, 0)
      Object.assign(state.list, [])
    },
    setDataInfo (state, data) {
      state.info = data || {}
    },
    setDataList (state, data) {
      state.list = data || {}
    },
    setDataCount (state, data) {
      state.count = data || 0
    },
    setDataTotalPage (state, data) {
      state.totalPage = data || 0
    },
  },
  actions: {
    getBranchSpaceList (context, payload) {

      return new Promise((resolve, reject) => {

        this._vm.get(`${this._vm.SPARK_ONE_CREW_API_URL}space/branch-space`, payload)
          .then((response) => {
            context.commit('setDataList', response.data.spaceList || [])
            context.commit('setDataCount', response.data.spaceCount || 0)

            if ( payload && typeof payload.limit !== 'undefined' )
              context.commit('setDataTotalPage', Math.ceil((response.data.spaceCount || 0)/(payload.limit)))

            resolve(response)
          })
          .catch((error) => reject(error))

      })

    },
    getBranchSpaceInfo (context, payload) {
      context.commit('setDataInfo', {})

      return new Promise((resolve, reject) => {

        this._vm.get(`${this._vm.SPARK_ONE_CREW_API_URL}space/branch-space/${payload.spaceId}`)
          .then(response => {
            context.commit('setDataInfo', response.data.spaceInfo)
            resolve(response)
          })
          .catch(error => reject(error))

      })

    },
    setBranchSpace (context, payload) {

      return new Promise((resolve, reject) => {

        this._vm.post(`${this._vm.SPARK_ONE_CREW_API_URL}space/branch-space`, payload)
          .then((response) => {
            resolve(response)
          })
          .catch((error) => reject(error))

      })

    },
  },
}
