export default {
    namespaced: true,
    state: {

    },
    getters: {

    },
    mutations: {

    },
    actions: {
        async getPopupList(context, payload) {
            let response = null

            try {
                response = await this._vm.get(
                    `${this._vm.SPARK_ONE_CREW_API_URL}common/popup`
                    , payload
                )
            } catch (error) {
                if (error.response) {
                    response = error
                } else {
                    throw error
                }
            }

            return response
        },
        async getPopupInfo(context, payload) {
            let response = null

            try {
                response = await this._vm.get(
                    `${this._vm.SPARK_ONE_CREW_API_URL}common/popup/${payload.bannerId}`,
                )
            } catch (error) {
                if (error.response) {
                    response = error
                } else {
                    throw error
                }
            }

            return response
        },
        async postPopup(context, payload) {
            let response = null

            try {
                response = await this._vm.post(
                    `${this._vm.SPARK_ONE_CREW_API_URL}common/popup`,
                    payload
                )
            } catch (error) {
                if (error.response) {
                    response = error
                } else {
                    throw error
                }
            }

            return response
        },
        async putPopup(context, payload) {
            let response = null

            try {
                response = await this._vm.put(
                    `${this._vm.SPARK_ONE_CREW_API_URL}common/popup/${payload.bannerId}`,
                    payload
                )
            } catch (error) {
                if (error.response) {
                    response = error
                } else {
                    throw error
                }
            }

            return response
        },
        async deletePopup(context, payload) {
            let response = null

            try {
                response = await this._vm.delete(
                    `${this._vm.SPARK_ONE_CREW_API_URL}common/popup/${payload.bannerId}`
                )
            } catch (error) {
                if (error.response) {
                    response = error
                } else {
                    throw error
                }
            }

            return response
        },
    },
}
