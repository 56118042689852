  export default {
    namespaced: true,
    getters: {

    },
    mutations: {

    },
    actions: {
      async cancelReservationMember ({ commit }, payload) {
        let response = null

        try {
          response = await this._vm.put(
            `${this._vm.SPARK_ONE_CREW_API_URL}reservation/cancel/${payload.memberId}`,
            payload,
          )
        } catch (error) {
          if (error.response) {
            response = error
          } else {
            throw error
          }
        }
        return response
      },
      async cancelAllReservation ({ commit }, userId) {
        let response = null

        try {
          response = await this._vm.put(
            `${this._vm.SPARK_ONE_CREW_API_URL}reservation/cancel/user/${userId}`
          )
        } catch (error) {
          if (error.response) {
            response = error
          } else {
            throw error
          }
          return response
        }
      }
    },
  }
