export default [
  {
    path: '/space/region',
    name: 'SpaceRegionList',
    meta: {
      requiresAuth: true,
      fullScreen: false,
    },
    component: () => import('@/components/space/region/List'),
  },
  {
    path: '/space/region/create',
    name: 'SpaceRegionRegist',
    meta: {
      requiresAuth: true,
      fullScreen: false,
    },
    component: () => import('@/components/space/region/Form'),
  },
  {
    path: '/space/region/:id/edit',
    name: 'SpaceRegionModify',
    meta: {
      requiresAuth: true,
      fullScreen: false,
    },
    component: () => import('@/components/space/region/Form'),
  },
  {
    path: '/space/city/:regionCode',
    name: 'SpaceCityDistrictList',
    meta: {
      requiresAuth: true,
      fullScreen: false,
    },
    component: () => import('@/components/space/city_district/List'),
  },
  {
    path: '/space/city/:regionCode/create',
    name: 'SpaceCityDistrictRegist',
    meta: {
      requiresAuth: true,
      fullScreen: false,
    },
    component: () => import('@/components/space/city_district/Form'),
  },
  {
    path: '/space/city/:regionCode/:cityCountyDistrictId/edit',
    name: 'SpaceCityDistrictModify',
    meta: {
      requiresAuth: true,
      fullScreen: false,
    },
    component: () => import('@/components/space/city_district/Form'),
  },
  {
    path: '/space/branch',
    name: 'SpaceBranchList',
    meta: {
      requiresAuth: true,
      fullScreen: false,
    },
    component: () => import('@/components/space/branch/List'),
  },
  {
    path: '/space/branch/create',
    name: 'SpaceBranchRegist',
    meta: {
      requiresAuth: true,
      fullScreen: false,
    },
    component: () => import('@/components/space/branch/Form'),
  },
  {
    path: '/space/branch/:id/edit',
    name: 'SpaceBranchModify',
    meta: {
      requiresAuth: true,
      fullScreen: false,
    },
    component: () => import('@/components/space/branch/Form'),
  },
  {
    path: '/space/branch-space-detail/:branchId',
    name: 'SpaceBranchSpaceDetail',
    meta: {
      requiresAuth: true,
      fullScreen: false,
    },
    component: () => import('@/components/space/branch/Space'),
  },
  {
    path: '/space/branch-asset/:branchId',
    name: 'SpaceBranchAsset',
    meta: {
      requiresAuth: true,
      fullScreen: false,
    },
    component: () => import('@/components/space/branch/Asset'),
  },
  {
    path: '/space/branch-day-schedule/:branchId',
    name: 'SpaceBranchDaySchedule',
    meta: {
      requiresAuth: true,
      fullScreen: false,
    },
    component: () => import('@/components/space/branch/DaySchedule'),
  },
  {
    path: '/space/access-policy/:branchId',
    name: 'SpaceAccessDetailPage',
    component: () => import('@/components/space/accessPolicy/DetailPage'),
    meta: {
      requiresAuth: true,
      fullScreen: false,
    },
  },
  {
    path: '/space/template/:assetTemplateId',
    name: 'SpaceTemplateModifyPage',
    component: () => import('@/components/space/template/ModifyPage'),
    meta: {
      requiresAuth: true,
      fullScreen: false,
      pageTitle: '스페이스 에셋 수정',
    },
  },
  {
    path: '/space/template',
    name: 'SpaceTemplateListPage',
    component: () => import('@/components/space/template/ListPage'),
    meta: {
      requiresAuth: true,
      fullScreen: false,
    },
  },
  {
    path: '/space/space-asset/:branchId',
    name: 'SpaceAssetListPage',
    component: () => import('@/components/space/spaceAsset/ListPage'),
    meta: {
      requiresAuth: true,
      fullScreen: false,
      pageTitle: '스페이스 에셋 등록',
    },
  },
  {
    path: '/space/space-asset/asset/:assetId',
    name: 'SpaceAssetListPageEdit',
    component: () => import('@/components/space/spaceAsset/ModifyPage'),
    meta: {
      requiresAuth: true,
      fullScreen: false,
      pageTitle: '스페이스 에셋 수정',
    },
  },
]
