const getDefaultState = () => {
  return {
    originFilter: {},
  }
}
export default {
  namespaced: true,
  state: getDefaultState(),
  getters: {
    totalPages: (state) => {
      const limit = state.originFilter?.limit || 50
      const count = state.count || 0
      return Math.ceil(count / limit)
    },
  },
  mutations: {
    resetState (state) {
      Object.assign(state, getDefaultState())
    },
  },
  actions: {
    async createSpaceAsset ({ commit }, payload) {
      let response = null
      try {
        response = await this._vm.post(
          `${this._vm.SPARK_ONE_CREW_API_URL}space/space-asset`,
          payload,
        )
      } catch (error) {
        if (error.response) {
          response = error
        } else {
          throw error
        }
      }
      return response
    },

    async cloneSpaceAsset ({ commit }, payload) {
      let response = null
      try {
        response = await this._vm.post(
          `${this._vm.SPARK_ONE_CREW_API_URL}space/space-asset/clone/${payload.originAssetId}`,
        )
      } catch (error) {
        if (error.response) {
          response = error
        } else {
          throw error
        }
      }
      return response
    },

    async deleteSpaceAsset ({ commit }, payload) {
      let response = null
      try {
        response = await this._vm.delete(
          `${this._vm.SPARK_ONE_CREW_API_URL}space/space-asset/${payload.assetId}`,
        )
      } catch (error) {
        if (error.response) {
          response = error
        } else {
          throw error
        }
      }
      return response
    },

    async getSpaceAsset ({ commit }, payload) {
      let response = null
      try {
        response = await this._vm.get(
          `${this._vm.SPARK_ONE_CREW_API_URL}space/space-asset/${payload.assetId}`,
        )
      } catch (error) {
        if (error.response) {
          response = error
        } else {
          throw error
        }
      }
      return response
    },

    async updateSpaceAsset ({ commit }, payload) {
      let response = null
      try {
        response = await this._vm.put(
          `${this._vm.SPARK_ONE_CREW_API_URL}space/space-asset/${payload.assetId}`,
          payload,
        )
      } catch (error) {
        if (error.response) {
          response = error
        } else {
          throw error
        }
      }
      return response
    },

    async setFloorDisplay ({ commit }, payload) {
      let response = null
      try {
        response = await this._vm.put(
          `${this._vm.SPARK_ONE_CREW_API_URL}space/branch/${payload.branchId}/floor/${payload.floorType}/display/${payload.displayYn}`,
        )
      } catch (error) {
        if (error.response) {
          response = error
        } else {
          throw error
        }
      }
      return response
    },

  },
}
