export default {
  namespaced: true,
  state: {
    info: {},
    list: [],
    count: 0,
  },
  getters: {

  },
  mutations: {
    resetState (state) {
      Object.assign(state.count, 0)
      Object.assign(state.list, [])
    },
    setDataInfo (state, data) {
      state.info = data || {}
    },
    setDataList (state, data) {
      state.list = data || {}
    },
    setDataCount (state, data) {
      state.count = data || 0
    },
  },
  actions: {
    delFile (context, payload) {

      return new Promise((resolve, reject) => {

        this._vm.delete(`${this._vm.SPARK_ONE_CREW_API_URL}common/file/s3_delete`, payload)
          .then((response) => {
            resolve(response)
          })
          .catch((error) => reject(error))

      })

    },
    delCommonFile (context, payload) {

      return new Promise((resolve, reject) => {
        this._vm.delete(`${this._vm.SPARK_ONE_CREW_API_URL}common/file/delete/${payload.id}`)
          .then((response) => {
            resolve(response)
          })
          .catch((error) => reject(error))

      })

    },
    async getDownloadUrl (context, payload) {
      let response = null
      try {
        response = await this._vm.get(
          `${this._vm.SPARK_ONE_CREW_API_URL}common/file/download-url/${payload.fileId}`,
        )
      } catch (error) {
        if (error.response) {
          response = error
        } else {
          throw error
        }
      }
      return response
    },

    // 매출관리쪽 파일다운로드용 api
    async getSalesDownloadUrl (context, payload) {
      let response = null
      try {
        response = await this._vm.get(
          `${this._vm.SPARK_ONE_CREW_API_URL}sales/file/download-url/${payload.fileId}`,
        )
      } catch (error) {
        if (error.response) {
          response = error
        } else {
          throw error
        }
      }
      return response
    },

    // 정산> 청구 결제완료 금액 증빙파일 다운로드 URL api
    async getBillingPaymentDownloadUrl (context, payload) {
      let response = null
      try {
        response = await this._vm.get(
          `${this._vm.SPARK_ONE_CREW_API_URL}settlement/v2/billing/file/payment/download-url/${payload.fileId}`,
        )
      } catch (error) {
        if (error.response) {
          response = error
        } else {
          throw error
        }
      }
      return response
    },

    // 정산> 청구 수정 증빙파일 다운로드 URL api
    async getBillingModifyDownloadUrl (context, payload) {
      let response = null
      try {
        response = await this._vm.get(
          `${this._vm.SPARK_ONE_CREW_API_URL}settlement/v2/billing/file/modify/download-url/${payload.fileId}`,
        )
      } catch (error) {
        if (error.response) {
          response = error
        } else {
          throw error
        }
      }
      return response
    },

  },
}
