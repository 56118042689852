export default {
  namespaced: true,
  state: {
    info: {},
    list: [],
    count: 0,
    totalPage: 0,
  },
  getters: {

  },
  mutations: {
    resetState (state) {
      Object.assign(state.count, 0)
      Object.assign(state.list, [])
    },
    setDataInfo (state, data) {
      state.info = data || {}
    },
    setDataList (state, data) {
      state.list = data || {}
    },
    setDataCount (state, data) {
      state.count = data || 0
    },
    setDataTotalPage (state, data) {
      state.totalPage = data || 0
    },
  },
  actions: {
    getBranchMeetingRoomList (context, payload) {

      payload.limit = 1000
      return new Promise((resolve, reject) => {

        this._vm.get(`${this._vm.SPARK_ONE_CREW_API_URL}space/branch-meeting-room`, payload)
          .then((response) => {
            context.commit('setDataList', response.data.meetingRoomList || [])
            context.commit('setDataCount', response.data.meetingRoomCount || 0)

            if ( payload && typeof payload.limit !== 'undefined' )
              context.commit('setDataTotalPage', Math.ceil((response.data.meetingRoomCount || 0)/(payload.limit)))

            resolve(response)
          })
          .catch((error) => reject(error))

      })

    },
    getBranchMeetingRoomInfo (context, payload) {
      context.commit('setDataInfo', {})

      return new Promise((resolve, reject) => {

        this._vm.get(`${this._vm.SPARK_ONE_CREW_API_URL}space/branch-meeting-room/${payload.meetingRoomId}`)
          .then(response => {
            context.commit('setDataInfo', response.data.meetingRoomInfo)
            resolve(response)
          })
          .catch(error => reject(error))

      })

    },
    setBranchMeetingRoom (context, payload) {

      return new Promise((resolve, reject) => {

        this._vm.post(`${this._vm.SPARK_ONE_CREW_API_URL}space/branch-meeting-room`, payload)
          .then((response) => {
            resolve(response)
          })
          .catch((error) => reject(error))

      })

    },
    putBranchMeetingRoom (context, payload) {

      return new Promise((resolve, reject) => {

        this._vm.put(`${this._vm.SPARK_ONE_CREW_API_URL}space/branch-meeting-room/${payload.meetingRoomId}`, payload)
          .then(response => {
            resolve(response)
          })
          .catch(error => reject(error))

      })

    },
    postBranchMeetingRoomExcelUpload (context, payload) {

      return new Promise((resolve, reject) => {

        this._vm.post(`${this._vm.SPARK_ONE_CREW_API_URL}space/branch-meeting-room-excel-upload`, payload)
          .then(response => {
            resolve(response)
          })
          .catch(error => reject(error))

      })

    },
    deleteBranchMeetingRoomImage (context, payload) {

      return new Promise((resolve, reject) => {

        this._vm.delete(`${this._vm.SPARK_ONE_CREW_API_URL}space/branch-meeting-room-image/${payload.fileId}`)
          .then(response => {
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
    getRentalinfo (context, payload) {
      return new Promise((resolve, reject) => {
        this._vm.get(`${this._vm.SPARK_ONE_CREW_API_URL}space/branch-meeting-room/rental/${payload.meetingRoomId}`)
          .then(response => {
            context.commit('setDataInfo', response.data)
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
    putRentalinfo (context, payload) {
      return new Promise((resolve, reject) => {
        this._vm.put(`${this._vm.SPARK_ONE_CREW_API_URL}space/branch-meeting-room/rental/${payload.meetingRoomId}`, payload)
          .then(response => {
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
    postRentalinfo (context, payload) {
      return new Promise((resolve, reject) => {
        this._vm.post(`${this._vm.SPARK_ONE_CREW_API_URL}space/branch-meeting-room/rental`, payload)
          .then(response => {
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
  },
}
