export default {
    namespaced: true,
    state: {

    },
    getters: {

    },
    mutations: {

    },
    actions: {
        async getBannerList(context, payload) {
            let response = null

            try {
                response = await this._vm.get(
                    `${this._vm.SPARK_ONE_CREW_API_URL}common/banner`
                    , payload
                )
            } catch (error) {
                if (error.response) {
                    response = error
                } else {
                    throw error
                }
            }

            return response
        },
        async getBannerInfo(context, payload) {
            let response = null

            try {
                response = await this._vm.get(
                    `${this._vm.SPARK_ONE_CREW_API_URL}common/banner/${payload.bannerId}`
                )
            } catch (error) {
                if (error.response) {
                    response = error
                } else {
                    throw error
                }
            }

            return response
        },
        async postBanner(context, payload) {
            let response = null

            try {
                response = await this._vm.post(
                    `${this._vm.SPARK_ONE_CREW_API_URL}common/banner`,
                    payload
                )
            } catch (error) {
                if (error.response) {
                    response = error
                } else {
                    throw error
                }
            }

            return response
        },
        async putBanner(context, payload) {
            let response = null

            try {
                response = await this._vm.put(
                    `${this._vm.SPARK_ONE_CREW_API_URL}common/banner/${payload.bannerId}`,
                    payload
                )
            } catch (error) {
                if (error.response) {
                    response = error
                } else {
                    throw error
                }
            }

            return response
        },
        async deleteBanner(context, payload) {
            let response = null

            try {
                response = await this._vm.delete(
                    `${this._vm.SPARK_ONE_CREW_API_URL}common/banner/${payload.bannerId}`
                )
            } catch (error) {
                if (error.response) {
                    response = error
                } else {
                    throw error
                }
            }

            return response
        },
        async postBannerGroup(context, payload) {
            let response = null

            try {
                response = await this._vm.post(
                    `${this._vm.SPARK_ONE_CREW_API_URL}common/bannerGroup`,
                    payload
                )
            } catch (error) {
                if (error.response) {
                    response = error
                } else {
                    throw error
                }
            }

            return response
        },
        async getBannerGroupInfo(context, payload) {
            let response = null

            try {
                response = await this._vm.get(
                    `${this._vm.SPARK_ONE_CREW_API_URL}common/bannerGroup/${payload.type}`
                )
            } catch (error) {
                if (error.response) {
                    response = error
                } else {
                    throw error
                }
            }

            return response
        },
        async putBannerGroup(context, payload) {
            let response = null

            try {
                response = await this._vm.put(
                    `${this._vm.SPARK_ONE_CREW_API_URL}common/bannerGroup/${payload.type}`,
                    payload
                )
            } catch (error) {
                if (error.response) {
                    response = error
                } else {
                    throw error
                }
            }

            return response
        },
        async getBranchList(context, payload) {
            let response = null

            try {
                response = await this._vm.get(
                    `${this._vm.SPARK_ONE_CREW_API_URL}space/branch`,
                    payload
                )
            } catch (error) {
                if (error.response) {
                    response = error
                } else {
                    throw error
                }
            }

            return response
        },
    },
}
