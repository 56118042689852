export default {
    namespaced: true,
    state: {

    },
    getters: {

    },
    mutations: {

    },
    actions: {
        async getTenantList(context, payload) {
            let response = null

            try {
                response = await this._vm.get(
                    `${this._vm.SPARK_ONE_CREW_API_URL}tenant`
                    , payload
                )
            } catch (error) {
                if (error.response) {
                    response = error
                } else {
                    throw error
                }
            }

            return response
        },
        async getTenantInfo(context, payload) {
            let response = null

            try {
                response = await this._vm.get(
                    `${this._vm.SPARK_ONE_CREW_API_URL}tenant/${payload.tenantId}`
                )
            } catch (error) {
                if (error.response) {
                    response = error
                } else {
                    throw error
                }
            }

            return response
        },
        async postTenant(context, payload) {
            let response = null

            try {
                response = await this._vm.post(
                    `${this._vm.SPARK_ONE_CREW_API_URL}tenant`,
                    payload
                )
            } catch (error) {
                if (error.response) {
                    response = error
                } else {
                    throw error
                }
            }

            return response
        },
        async putTenant(context, payload) {
            let response = null

            try {
                response = await this._vm.put(
                    `${this._vm.SPARK_ONE_CREW_API_URL}tenant/${payload.tenantId}`,
                    payload
                )
            } catch (error) {
                if (error.response) {
                    response = error
                } else {
                    throw error
                }
            }

            return response
        },
        async getCorporationRegistrationNumberInfo(context, payload) {
            let response = null

            try {
                response = await this._vm.get(
                    `${this._vm.SPARK_ONE_CREW_API_URL}corporationRegistrationNumber/${payload.corporationRegistrationNumber}`
                )
            } catch (error) {
                if (error.response) {
                    response = error
                } else {
                    throw error
                }
            }

            return response
        },
        async getTenantDepartmentList(context, payload) {
            let response = null

            try {
                response = await this._vm.get(
                    `${this._vm.SPARK_ONE_CREW_API_URL}tenant/department/${payload.tenantId}`
                )
            } catch (error) {
                if (error.response) {
                    response = error
                } else {
                    throw error
                }
            }

            return response
        },
        async getTenantPositionList(context, payload) {
            let response = null

            try {
                response = await this._vm.get(
                    `${this._vm.SPARK_ONE_CREW_API_URL}tenant/position/${payload.tenantId}`
                )
            } catch (error) {
                if (error.response) {
                    response = error
                } else {
                    throw error
                }
            }

            return response
        },
        async getTenantAccountList(context, payload) {
            let response = null

            try {
                response = await this._vm.get(
                    `${this._vm.SPARK_ONE_CREW_API_URL}tenant/account/${payload.tenantId}`
                )
            } catch (error) {
                if (error.response) {
                    response = error
                } else {
                    throw error
                }
            }
            return response
        },
        async getTenantExcelList (context, payload) {
            let response = null

            try {
              response = await this._vm.get(
                `${this._vm.SPARK_ONE_CREW_API_URL}tenant/tenant-list/excel`
                , payload
              )
            } catch (error) {
              if (error.response) {
                response = error
              } else {
                throw error
              }
            }

            return response
        },
    },
}
