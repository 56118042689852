const getDefaultState = () => {
  return {
    list: [],
    main: {},
    item: null,
    filter: [],
    originFilter: {},
  }
}
export default {
  namespaced: true,
  state: getDefaultState(),
  getters: {

  },
  mutations: {
    SET_DATA_MAIN (state, data) {
      state.main = data
    },
    SET_DATA_FILTER (state, data) {
      state.filter = data
    },
    SET_DATA_BANNER (state, data) {
      state.list = data
    },
    SET_DATA_ITEM (state, data) {
      state.item = data
    },
    RESET_DATA_ITEM (state) {
      state.item = {}
    },
    RESET_DATA (state) {
      state.main = {}
      state.filter = {}
      state.list = {}
      state.item = null
    },
  },
  actions: {
    async getSpaceRentalMain ({ commit }, payload) {
      let response = null
      try {
        response = await this._vm.get(`${this._vm.SPARK_ONE_CREW_API_URL}rental/cms/main`)
        commit('SET_DATA_MAIN', response.data)
      } catch (error) {
        if (error.response) {
          response = error
        } else {
          throw error
        }
      }
      return response
    },
    async putSpaceRentalMain ({ commit }, payload) {
      let response = null
      try {
        response = await this._vm.put(`${this._vm.SPARK_ONE_CREW_API_URL}rental/cms/main/modify`, payload)
      } catch (error) {
        if (error.response) {
          response = error
        } else {
          throw error
        }
      }
      return response
    },
    async getSpaceRentalBanner ({ commit }, payload) {
      let response = null
      try {
        response = await this._vm.get(`${this._vm.SPARK_ONE_CREW_API_URL}rental/banner/list`)
        commit('SET_DATA_BANNER', response.data)
      } catch (error) {
        if (error.response) {
          response = error
        } else {
          throw error
        }
      }
      return response
    },
    async getSpaceRentalBannerItem ({ commit }, payload) {
      let response = null
      try {
        response = await this._vm.get(`${this._vm.SPARK_ONE_CREW_API_URL}rental/banner/${payload}`)
        commit('RESET_DATA_ITEM')
        commit('SET_DATA_ITEM', response.data)
      } catch (error) {
        if (error.response) {
          response = error
        } else {
          throw error
        }
      }
      return response
    },
    async putSpaceRentalBannerItem ({ commit }, payload) {
      let response = null
      try {
        const bannerId = payload.bannerId
        delete payload.bannerId
        response = await this._vm.put(`${this._vm.SPARK_ONE_CREW_API_URL}rental/banner/${bannerId}`, payload)
      } catch (error) {
        if (error.response) {
          response = error
        } else {
          throw error
        }
      }
      return response
    },
    async postSpaceRentalBannerItem ({ commit }, payload) {
      let response = null
      try {
        response = await this._vm.post(`${this._vm.SPARK_ONE_CREW_API_URL}rental/banner`, payload)
      } catch (error) {
        if (error.response) {
          response = error
        } else {
          throw error
        }
      }
      return response
    },
    async getSpaceRentalFilter ({ commit }, payload) {
      let response = null
      try {
        response = await this._vm.get(`${this._vm.SPARK_ONE_CREW_API_URL}rental/cms/main/filter`)
        commit('SET_DATA_FILTER', response.data)
      } catch (error) {
        if (error.response) {
          response = error
        } else {
          throw error
        }
      }
      return response
    },
    async getSpaceRentalFilterItem ({ commit }, payload) {
      let response = null
      try {
        response = await this._vm.get(`${this._vm.SPARK_ONE_CREW_API_URL}rental/cms/main/filter_detail/${payload}`)
        commit('RESET_DATA_ITEM')
        commit('SET_DATA_ITEM', response.data)
      } catch (error) {
        if (error.response) {
          response = error
        } else {
          throw error
        }
      }
      return response
    },
    async postSpaceRentalFilter ({ commit }, payload) {
      let response = null
      try {
        response = await this._vm.post(`${this._vm.SPARK_ONE_CREW_API_URL}rental/cms/main/filter`, payload)
      } catch (error) {
        if (error.response) {
          response = error
        } else {
          throw error
        }
      }
      return response
    },
    async putSpaceRentalFilterItem ({ commit }, payload) {
      let response = null
      try {
        response = await this._vm.put(`${this._vm.SPARK_ONE_CREW_API_URL}rental/cms/main/filter`, payload)
      } catch (error) {
        if (error.response) {
          response = error
        } else {
          throw error
        }
      }
      return response
    },
  },
}
