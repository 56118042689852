const getDefaultState = () => {
    return {
        list: null,
        count: 0,
        originFilter: {},
    }
}
export default {
    namespaced: true,
    state: getDefaultState(),
    getters: {
        totalPages: state => {
            const limit = state.originFilter?.limit || 20
            const count = state.count || 0
            return Math.ceil(count / limit)
        },
    },
    mutations: {
        resetState(state) {
            Object.assign(state, getDefaultState())
        },
        setListResponse(state, data) {
            state.list = data.userLoginHistoryList
            state.count = data.totalCount
        },
        setOriginFilter(state, filter) {
            state.originFilter = JSON.parse(JSON.stringify(filter))
        },
    },
    actions: {
        async getUserLoginHistoryList({ commit }, payload) {
            let response = null

            try {
                response = await this._vm.get(
                    `${this._vm.SPARK_ONE_CREW_API_URL}users/login-history`
                    , payload
                )
                commit('setListResponse', response.data)
                commit('setOriginFilter', payload)
            } catch (error) {
                if (error.response) {
                    response = error
                } else {
                    throw error
                }
            }

            return response
        },
    },
}
