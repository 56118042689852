export default {
  namespaced: true,
  state: {
    isModify: false,
    list: [],
    routers: {},
  },
  getters: {

  },
  mutations: {
    resetState (state) {
      Object.assign(state.list, [])
    },
    setResponse (state, data) {
      state.list = data.departmentList
    },
    setRouterList (state, data) {
      state.routers = data || {}
    },
  },
  actions: {
    async getDepartmentList ({ commit }, payload) {
      let response = null
      try {
        response = await this._vm.get(
          `${this._vm.SPARK_ONE_CREW_API_URL}department`,
          payload,
        )
        commit('setResponse', response.data)
      } catch (error) {
        if (error.response) {
          response = error
        } else {
          throw error
        }
      }
      return response
    },
  },
}
