const getDefaultState = () => {
  return {
    list: null,
    summarylist: null,
    count: 0,
    originFilter: {},
  }
}
export default {
  namespaced: true,
  state: getDefaultState(),
  getters: {
    totalPages: state => {
      const limit = state.originFilter?.limit || 20
      const count = state.count || 0
      return Math.ceil(count / limit)
    },
  },
  mutations: {
    resetState (state) {
      Object.assign(state, getDefaultState())
    },
    setResponse (state, data) {
      state.list = data.billingList
      state.summarylist = data.billingListSummaryAmount
      state.count = data.totalCount
    },
    setOriginFilter (state, filter) {
      state.originFilter = JSON.parse(JSON.stringify(filter))
    },
  },
  actions: {
    async getBillingList ({ commit }, payload) {
      let response = null
      try {
        response = await this._vm.get(
                    `${this._vm.SPARK_ONE_CREW_API_URL}settlement/v2/billing`,
                    payload,
        )
        commit('setResponse', response.data)
        commit('setOriginFilter', payload)
      } catch (error) {
        if (error.response) {
          response = error
        } else {
          throw error
        }
      }
      return response
    },

    // 청구내역 확정
    async putBillingConfirmed (_context, payload) {
      let response = null
      try {
        response = await this._vm.put(
                    `${this._vm.SPARK_ONE_CREW_API_URL}settlement/v2/billing/confirmed`,
                    payload,
        )
      } catch (error) {
        if (error.response) {
          response = error
        } else {
          throw error
        }
      }
      return response
    },

    // 청구 배포 처리(파이낸스만 가능)
    async postBillingRelease (_context, payload) {
      let response = null
      try {
        response = await this._vm.post(
                    `${this._vm.SPARK_ONE_CREW_API_URL}settlement/v2/billing/release`,
                    payload,
        )
      } catch (error) {
        if (error.response) {
          response = error
        } else {
          throw error
        }
      }
      return response
    },

    // 청구 수정마감 처리
    async postBillingClosechange (_context, payload) {
      let response = null
      try {
        response = await this._vm.post(
                    `${this._vm.SPARK_ONE_CREW_API_URL}settlement/v2/billing/closechange`,
                    payload,
        )
      } catch (error) {
        if (error.response) {
          response = error
        } else {
          throw error
        }
      }
      return response
    },

    // 청구 상세 결제금액 팝업 조회
    async getBillingPaymentPrice (_context, payload) {
      let response = null
      try {
        response = await this._vm.get(
                    `${this._vm.SPARK_ONE_CREW_API_URL}settlement/v2/billing/payment-price/${payload.billingId}`,

        )
      } catch (error) {
        if (error.response) {
          response = error
        } else {
          throw error
        }
      }
      return response
    },

    // 결제완료 금액 저장
    async putBillingPaymentPrice (_context, payload) {
      let response = null
      try {
        response = await this._vm.put(
                    `${this._vm.SPARK_ONE_CREW_API_URL}settlement/v2/billing/payment-price/${payload.billingId}`,
                    payload.data,

        )
      } catch (error) {
        if (error.response) {
          response = error
        } else {
          throw error
        }
      }
      return response
    },

    // 청구 상세 조회 팝업
    async getBillingDetail ({ commit }, payload) {
      let response = null
      try {
        response = await this._vm.get(
                    `${this._vm.SPARK_ONE_CREW_API_URL}settlement/v2/billing/${payload.billingId}`,
        )
      } catch (error) {
        if (error.response) {
          response = error
        } else {
          throw error
        }
      }
      return response
    },

    // 청구 상세 수정 팝업
    async putBillingDetail ({ commit }, payload) {
      let response = null
      try {
        response = await this._vm.put(
                    `${this._vm.SPARK_ONE_CREW_API_URL}settlement/v2/billing/${payload.billingId}`, payload.data,
        )
      } catch (error) {
        if (error.response) {
          response = error
        } else {
          throw error
        }
      }
      return response
    },

    // 배부 처리 이력 조회 API
    async getBillingDistributionProcessingHistory ({ commit }, payload) {
      let response = null
      try {
        response = await this._vm.get(
                    `${this._vm.SPARK_ONE_CREW_API_URL}settlement/v2/billing/distribution-processing-history/${payload.billingId}`,
        )
      } catch (error) {
        if (error.response) {
          response = error
        } else {
          throw error
        }
      }
      return response
    },

    // 지연손해금 생성 이력 조회 API
    async getBillingDelayDamagesCreationHistory ({ commit }, payload) {
      let response = null
      try {
        response = await this._vm.get(
                    `${this._vm.SPARK_ONE_CREW_API_URL}settlement/v2/billing/delay-damages-creation-history/${payload.billingId}`,
        )
      } catch (error) {
        if (error.response) {
          response = error
        } else {
          throw error
        }
      }
      return response
    },

    // 지연손해금 생성 이력 수정사항 저장 API
    async putBillingDelayDamagesCreationHistory ({ commit }, payload) {
      let response = null
      try {
        response = await this._vm.put(
                    `${this._vm.SPARK_ONE_CREW_API_URL}settlement/v2/billing/delay-damages-creation-history-update/${payload.billingId}`, payload.data,
        )
      } catch (error) {
        if (error.response) {
          response = error
        } else {
          throw error
        }
      }
      return response
    },

    // 지연손해금 결제처리 이력 조회 API
    async getBillingDelayDamagesPaymentHistory ({ commit }, payload) {
      let response = null
      try {
        response = await this._vm.get(
                    `${this._vm.SPARK_ONE_CREW_API_URL}settlement/v2/billing/delay-damages-payment-history/${payload.billingId}`,
        )
      } catch (error) {
        if (error.response) {
          response = error
        } else {
          throw error
        }
      }
      return response
    },

    // 엑셀 리스트 불러오기
    async getBillingExcelList (_context, payload) {
      let response = null
      try {
        response = await this._vm.get(
                `${this._vm.SPARK_ONE_CREW_API_URL}settlement/v2/billing/excel`,
                payload,
        )
      } catch (error) {
        if (error.response) {
          response = error
        } else {
          throw error
        }
      }
      return response
    },

    // 홈택스 리스트 불러오기
    async getBillingExcelHometax (_context, payload) {
      let response = null
      try {
        response = await this._vm.get(
                `${this._vm.SPARK_ONE_CREW_API_URL}settlement/v2/billing/excel-hometax`,
                payload,
        )
      } catch (error) {
        if (error.response) {
          response = error
        } else {
          throw error
        }
      }
      return response
    },

    // ###################
    // Link 기능
    // ###################

    // 결제 링크 리스트 상세 조회 API (정산 > 청구관리 > 링크생성 팝업 초기값에 사용중)
    async getLinkPaymentDetail (_context, payload) {
      let response = null
      try {
        response = await this._vm.get(
                `${this._vm.SPARK_ONE_CREW_API_URL}settlement/v2/link-payment/${payload.billingId}`,
        )
      } catch (error) {
        if (error.response) {
          response = error
        } else {
          throw error
        }
      }
      return response
    },

    // 결제 링크 생성 API
    async postLinkPaymentCreate (_context, payload) {
      let response = null
      try {
        response = await this._vm.post(
                `${this._vm.SPARK_ONE_CREW_API_URL}settlement/v2/link-payment`,
                payload,
        )
      } catch (error) {
        if (error.response) {
          response = error
        } else {
          throw error
        }
      }
      return response
    },

    // 결제 링크 조회 API (정산 > 청구관리 > 링크생성 팝업 생성후에 조회할떄 사용중)
    async getLinkPaymentHistoryDetail (_context, payload) {
      let response = null
      try {
        response = await this._vm.get(
                `${this._vm.SPARK_ONE_CREW_API_URL}settlement/v2/link-payment-history/${payload.linkPaymentUrlId}`,
        )
      } catch (error) {
        if (error.response) {
          response = error
        } else {
          throw error
        }
      }
      return response
    },

    // 결제 링크 만료 API (정산 > 청구관리 > 링크생성 팝업 생성후에 취소할때 사용중)
    async putLinkPaymentExpired (_context, payload) {
      let response = null
      try {
        response = await this._vm.put(
                `${this._vm.SPARK_ONE_CREW_API_URL}settlement/v2/link-payment/expired/${payload.linkPaymentUrlId}`,
        )
      } catch (error) {
        if (error.response) {
          response = error
        } else {
          throw error
        }
      }
      return response
    },

    // 결제 링크 재생성 API (정산 > 청구관리 > 링크생성 팝업 생성후에 재생성시에 사용중)
    async putLinkReCreate (_context, payload) {
      let response = null
      try {
        response = await this._vm.put(
                `${this._vm.SPARK_ONE_CREW_API_URL}settlement/v2/link-payment/re-create/${payload.linkPaymentUrlId}`,
                payload.data,
        )
      } catch (error) {
        if (error.response) {
          response = error
        } else {
          throw error
        }
      }
      return response
    },

    // 배포 이메일 전송
    async putDeploymentEmail (_context, payload) {
      let response = null
      try {
        response = await this._vm.put(
                `${this._vm.SPARK_ONE_CREW_API_URL}settlement/v2/billing/email/`,
                payload,
        )
      } catch (error) {
        if (error.response) {
          response = error
        } else {
          throw error
        }
      }
      return response
    },

    // 배포 이메일 전송
    async getDeploymentEmailCompleteCheck (_context, payload) {
      let response = null
      try {
        response = await this._vm.get(
                `${this._vm.SPARK_ONE_CREW_API_URL}settlement/v2/billing/email/${payload.sendKey}`,
        )
      } catch (error) {
        if (error.response) {
          response = error
        } else {
          throw error
        }
      }
      return response
    },

  },
}
