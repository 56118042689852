const getDefaultState = () => {
  return {
    list: null,
    item: {},
    count: 0,
    originFilter: {},
  }
}
export default {
  namespaced: true,
  state: getDefaultState(),
  getters: {

  },
  mutations: {
    SET_DATA (state, data) {
      state.item = data
    },
    RESET_DATA (state) {
      state.item = {}
    },
    setResponse (state, data) {
      state.list = data
      state.count = data.totalCount
    },
    setOriginFilter (state, filter) {
      state.originFilter = JSON.parse(JSON.stringify(filter))
    },
  },
  actions: {
    async getSpaceRentalCmsDetail ({ commit }, payload) {
      let response = null
      try {
        response = await this._vm.get(`${this._vm.SPARK_ONE_CREW_API_URL}rental/cms/detail/${payload}`)
        commit('SET_DATA', response.data)
      } catch (error) {
        if (error.response) {
          response = error
        } else {
          throw error
        }
      }
      return response
    },
    async postSpaceRentalCmsDetail ({ commit }, payload) {
      let response = null
      try {
        const rentalPreferencesId = payload.rentalPreferencesId
        delete payload.rentalPreferencesId
        response = await this._vm.post(`${this._vm.SPARK_ONE_CREW_API_URL}rental/cms/detail/regist/${rentalPreferencesId}`, payload)
      } catch (error) {
        if (error.response) {
          response = error
        } else {
          throw error
        }
      }
      return response
    },
    async putSpaceRentalCmsDetailModify ({ commit }, payload) {
      let response = null
      try {
        const rentalPreferencesId = payload.rentalPreferencesId
        delete payload.rentalPreferencesId
        response = await this._vm.put(`${this._vm.SPARK_ONE_CREW_API_URL}rental/cms/detail/modify/${rentalPreferencesId}`, payload)
      } catch (error) {
        if (error.response) {
          response = error
        } else {
          throw error
        }
      }
      return response
    },
  },
}
