export default {
    namespaced: true,
    state: {
      info: {},
      list: [],
      count: 0,
      totalPage: 0,
      isUploadProcessing: false,
      isDeleteProcessing: false,
    },
    getters: {
  
    },
    mutations: {
      resetState (state) {
        Object.assign(state.count, 0)
        Object.assign(state.list, [])
      },
      setDataInfo (state, data) {
        state.info = data || {}
      },
      setDataList (state, data) {
        state.list = data || {}
      },
      setDataCount (state, data) {
        state.count = data || 0
      },
      setDataTotalPage (state, data) {
        state.totalPage = data || 0
      },
      setUploadProcessing (state, data) {
        state.isUploadProcessing = data || false
      },
      setDeleteProcessing (state, data) {
        state.isDeleteProcessing = data || false
      },
    },
    actions: {
      postArticleFileUpload (context, payload) {
  
        context.commit('setUploadProcessing', true)
        return new Promise((resolve, reject) => {
  
          this._vm.post(`${this._vm.SPARK_ONE_CREW_API_URL}contents/article-file/upload`, payload, {
              headers: {
                'Content-Type': 'multipart/form-data'
              },
            })
            .then(response => {
              resolve(response.uploadFileInfo || {})
            })
            .catch(error => reject(error))
            .finally(() => {
              context.commit('setUploadProcessing', false)
            })
  
        })
  
      },
      delArticleFile (context, payload) {
  
        context.commit('setDeleteProcessing', true)
        return new Promise((resolve, reject) => {
  
          this._vm.delete(`${this._vm.SPARK_ONE_CREW_API_URL}contents/article-file`, payload)
            .then(response => {
              resolve(response || {})
            })
            .catch(error => reject(error))
            .finally(() => {
              context.commit('setDeleteProcessing', false)
            })
  
        })
  
      },
    },
  }
  