const getDefaultState = () => {
    return {
      list: [],
      count: 0,
      originFilter: {},
    }
  }
  export default {
    namespaced: true,
    state: getDefaultState(),
    getters: {
      totalPages: state => {
        const limit = state.originFilter?.limit || 20
        const count = state.count || 0
        return Math.ceil(count / limit)
      },
    },
    mutations: {
      resetState (state) {
        Object.assign(state, getDefaultState())
      },
      setResponse (state, data) {
        state.list = data.products
        state.count = data.totalCount
      },
      setOriginFilter (state, filter) {
        state.originFilter = JSON.parse(JSON.stringify(filter))
      },
    },
    actions: {
      async getList ({ commit }, payload) {
        let response = null
        try {
          response = await this._vm.get(
            `${this._vm.SPARK_ONE_CREW_API_URL}product/membership`,
            payload,
          )
          commit('setResponse', response.data)
          commit('setOriginFilter', payload)
        } catch (error) {
          if (error.response) {
            response = error
          } else {
            throw error
          }
        }
        return response
      },
      async getInfo ({ commit }, payload) {
        let response = null
        try {
          response = await this._vm.get(
            `${this._vm.SPARK_ONE_CREW_API_URL}product/membership/${payload.productId}`,
          )
        } catch (error) {
          if (error.response) {
            response = error
          } else {
            throw error
          }
        }
        return response
      },
      async postInfo ({ commit }, payload) {
        let response = null
        try {
          response = await this._vm.post(
            `${this._vm.SPARK_ONE_CREW_API_URL}product/membership`,
            payload,
          )
        } catch (error) {
          if (error.response) {
            response = error
          } else {
            throw error
          }
        }
        return response
      },
      async putInfo ({ commit }, payload) {
        let response = null
        try {
          response = await this._vm.put(
            `${this._vm.SPARK_ONE_CREW_API_URL}product/membership/${payload.productId}`,
            payload,
          )
        } catch (error) {
          if (error.response) {
            response = error
          } else {
            throw error
          }
        }
        return response
      },
    },
  }
