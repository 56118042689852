<template>
  <div
  class="tab"
  :class="{
    'flex vertical': direction === 'vertical',
    'not-stretch': !stretch,
  }">
    <ul
    class="groups"
    :class="{ 'flex': direction === 'horizontal', }">
      <li
      v-for="(tab, index) in tabList"
      :key="index"
      class="tab-button">
        <label
        :for="`${_uid}${index}`"
        v-text="tab"
        class="tab-label"
        :class="{ 'active': index + 1 === activeTab, }"/>
        <input
        :id="`${_uid}${index}`"
        type="radio"
        :name="`${_uid}-tab`"
        :value="index + 1"
        v-model="myActiveTab"
        v-show="false"/>
      </li>
    </ul>
    <template v-for="(tab, index) in tabList">
      <template v-if="!isMaintained">
        <div
        :key="index"
        v-if="index + 1 === activeTab"
        class="tab-content">
          <slot :name="`tabPanel-${index + 1}`" />
        </div>
      </template>
      <template v-else>
        <div
          :key="index"
          v-show="index + 1 === activeTab"
          class="tab-content">
          <slot :name="`tabPanel-${index + 1}`" />
        </div>
      </template>
    </template>
  </div>
</template>

<script>
export default {
  props: {
    tabList: {
      type: Array,
      required: true,
    },
    stretch: {
      type: Boolean,
      default: true,
    },
    direction: {
      type: String,
      required: false,
      default: () => 'horizontal',
      validator: (value) => ['horizontal', 'vertical'].includes(value),
    },
    activeTab: {
      type: Number,
      required: true,
      default: 1,
    },
    isMaintained: { // 탭 클릭시 다른 탭 컨텐츠 유지 여부
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data () {
    return {
      // activeTab: 1,
    }
  },
  computed: {
    myActiveTab: {
      get () {
        return this.activeTab
      },
      set (a) {
        this.$emit('update:activeTab', a)
      },
    },
  },
}
</script>

<style lang="scss" scoped>
  .tab {
    &:last-of-type {
      margin-bottom: 10px;
    }
    box-sizing: border-box;
    // margin: 10px 10px 0 10px;
    border: 1px solid #ddd;
    border-radius:0 0 5px 5px;
    overflow: hidden;
    .tab-content{
      flex-grow: 1;
      // background: #fcfcfc77;
      border-top: 0;
      // padding: 5px;
      min-height: 80px;
      box-sizing: border-box;
      overflow: auto;
    }

    .groups {
      list-style-type: none;
      text-align: center;
      overflow: auto;
      white-space: nowrap;
      padding: 0;
      margin: 0;
      .tab-button {
        width:100%;
        min-width:150px;
        font-weight:600;
        .tab-label {
          font-size: 14px;
          height: 30px;
          line-height: 30px;
          cursor: pointer;
          display: block;
          margin:0;
          // background: #f0edeeaa;
          background: #F6F7F9;
          border-top: 6px solid #f0edeeaa;
          padding-bottom: 5px;
        }
        .active {
          // background: #fcfcfc77;
          border-top: 6px solid var(--theme-primary-color);
        }
      }
    }

    &.not-stretch {
      border: none;
      .groups {
        overflow: visible;
        border-bottom: 1px solid #e8e8e8;
        .tab-button {
          width: auto;
          min-width: auto;
          label {
            position: relative;
            padding: 0 4px;
            height: 30px;
            color: #A0A0A0;
            line-height: 30px;
            border-top: 0;
            background-color: transparent;
            &.active {
              color: #000;
              &:after {
                display: block;
                position: absolute;
                left: 0;
                bottom: -2px;
                width: 100%;
                height: 2px;
                background-color: #000;
                content: "";
              }
            }
          }
          &:nth-child(n+2) {
            margin-left: 20px;
          }
        }
      }
    }
  }
  .vertical {
    border-radius:0 5px 5px 0;
    .groups .tab-button {
      .tab-label {
        // height: 30px;
        // line-height: 30px;
        border-left: 6px solid #f0edeeaa;
        border-top: 0;
      }
      .active {
        border-top: 0;
        // background: #fcfcfc77;
        border-left: 6px solid var(--theme-primary-color);
      }
    }

  }
  .flex {
    display: flex;
  }

</style>
