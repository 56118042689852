const getDefaultState = () => {
  return {
    object: '',
  }
}
export default {
  namespaced: true,
  state: getDefaultState(),
  getters: {
  },
  mutations: {
    resetState (state) {
      Object.assign(state, getDefaultState())
    },
    setResponse (state, data) {
      state.object = data.account_info
    },
  },
  actions: {
    async getAccountObject ({ commit }, payload) {
      let response = null
      try {
        response = await this._vm.get(
          `${this._vm.SPARK_ONE_CREW_API_URL}account`,
          payload,
        )

        delete response.data.account_info.accountStatusName
        delete response.data.account_info.registrationAdminName
        delete response.data.account_info.registrationAdminNo
        delete response.data.account_info.registrationDatetime

        response.data.account_info.password = ''

        commit('setResponse', response.data)
      } catch (error) {
        if (error.response) {
          response = error
        } else {
          throw error
        }
      }
      return response
    },
    // 계정 수정
    async putAccount (context, payload) {
      let response = null
      try {
        response = await this._vm.put(
          `${this._vm.SPARK_ONE_CREW_API_URL}account`,
          payload,
        )
      } catch (error) {
        if (error.response) {
          response = error
        } else {
          throw error
        }
      }
      return response
    },
  },
}
