export default {
  namespaced: true,
  state: {
    info: {},
    list: [],
    count: 0,
    totalPage: 0,
    category: {},
    daySchedule: {},
    originFilter: {},
  },
  getters: {
    totalPages: (state) => {
      const limit = state.originFilter?.limit || 20
      const count = state.count || 0
      return Math.ceil(count / limit)
    },
  },
  mutations: {
    resetState (state) {
      Object.assign(state.count, 0)
      Object.assign(state.list, [])
    },
    setDataInfo (state, data) {
      state.info = data || {}
    },
    setDataList (state, data) {
      state.list = data || {}
    },
    setDataCount (state, data) {
      state.count = data || 0
    },
    setCategory (state, data) {
      state.category = data || {}
    },
    setDaySchedule (state, data) {
      state.daySchedule.visitableList = data.visitableList || []
      state.daySchedule.invitableList = data.invitableList || []
    },
    setDataTotalPage (state, data) {
      state.totalPage = data || 0
    },
    setOriginFilter (state, filter) {
      state.originFilter = JSON.parse(JSON.stringify(filter))
    },
  },
  actions: {
    async getBranchList ({ commit }, payload) {
      let response = null
      try {
        response = await this._vm.get(
          `${this._vm.SPARK_ONE_CREW_API_URL}space/branch`, payload
        )
        commit('setDataList', response.data.branchList || [])
        commit('setDataCount', response.data.branchCount || 0)
        commit('setOriginFilter', payload)
      } catch (error) {
        if (error.response) {
          response = error
        } else {
          throw error
        }
      }
      return response
    },
    getBranchDayScheduleList (context, payload) {

      return new Promise((resolve, reject) => {

        this._vm.get(`${this._vm.SPARK_ONE_CREW_API_URL}space/branch-day-schedule`, payload)
          .then((response) => {
            context.commit('setDaySchedule', response.data)

            resolve(response)
          })
          .catch((error) => reject(error))

      })

    },
    getBranchCategoryGroup (context, payload) {

      return new Promise((resolve, reject) => {

        this._vm.get(`${this._vm.SPARK_ONE_CREW_API_URL}space/branch-categories`)
          .then((response) => {
            context.commit('setCategory', response.data || {})

            resolve(response)
          })
          .catch((error) => reject(error))

      })

    },
    getBranchInfo (context, payload) {
      context.commit('setDataInfo', {})

      return new Promise((resolve, reject) => {

        this._vm.get(`${this._vm.SPARK_ONE_CREW_API_URL}space/branch/${payload.branchId}`)
          .then(response => {
            context.commit('setDataInfo', response.data.branchInfo)
            resolve(response)
          })
          .catch(error => reject(error))

      })

    },
    setBranch (context, payload) {

      return new Promise((resolve, reject) => {

        this._vm.post(`${this._vm.SPARK_ONE_CREW_API_URL}space/branch`, payload)
          .then((response) => {
            resolve(response)
          })
          .catch((error) => reject(error))

      })

    },
    setBranchDaySchedule (context, payload) {

      return new Promise((resolve, reject) => {

        this._vm.post(`${this._vm.SPARK_ONE_CREW_API_URL}space/branch-day-schedule`, payload)
          .then((response) => {
            resolve(response)
          })
          .catch((error) => reject(error))

      })

    },
    putBranch (context, payload) {

      return new Promise((resolve, reject) => {

        this._vm.put(`${this._vm.SPARK_ONE_CREW_API_URL}space/branch/${payload.branchId}`, payload)
          .then(response => {
            resolve(response)
          })
          .catch(error => reject(error))

      })

    },
    delBranchFile (context, payload) {

      return new Promise((resolve, reject) => {

        this._vm.delete(`${this._vm.SPARK_ONE_CREW_API_URL}space/branch-file/${payload.fileId}`, payload)
          .then(response => {
            resolve(response)
          })
          .catch(error => reject(error))

      })

    },
    delBranchFeature (context, payload) {

      return new Promise((resolve, reject) => {

        this._vm.delete(`${this._vm.SPARK_ONE_CREW_API_URL}space/branch-feature/${payload.branchFeatureId}`, payload)
          .then(response => {
            resolve(response)
          })
          .catch(error => reject(error))

      })

    },

  },
}
