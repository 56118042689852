export default {
    namespaced: true,
    state: {

    },
    getters: {

    },
    mutations: {

    },
    actions: {
        async getCategoryList(context, payload) {
            let response = null

            try {
                response = await this._vm.get(
                    `${this._vm.SPARK_ONE_CREW_API_URL}corporate/category`,
                )
            } catch (error) {
                if (error.response) {
                    response = error
                } else {
                    throw error
                }
            }

            return response
        },
        async getCategoryInfo(context, payload) {
            let response = null

            try {
                response = await this._vm.get(
                    `${this._vm.SPARK_ONE_CREW_API_URL}corporate/category/${payload.categoryId}`,
                    payload
                )
            } catch (error) {
                if (error.response) {
                    response = error
                } else {
                    throw error
                }
            }

            return response
        },
        async getTopCategoryInfo(context, payload) {
            let response = null

            try {
                response = await this._vm.get(
                    `${this._vm.SPARK_ONE_CREW_API_URL}corporate/top/category`,
                )
            } catch (error) {
                if (error.response) {
                    response = error
                } else {
                    throw error
                }
            }

            return response
        },
    },
}
