const getDefaultState = () => {
  return {
    list: null,
    count: 0,
    originFilter: {},
  }
}
export default {
  namespaced: true,
  state: getDefaultState(),
  getters: {
    totalPages: state => {
      const limit = state.originFilter?.limit || 20
      const count = state.count || 0
      return Math.ceil(count / limit)
    },
  },
  mutations: {
    resetState (state) {
      Object.assign(state, getDefaultState())
    },
    setResponse (state, data) {
      //임시데이터
      const dataList = [
        {
          membershipId:100,
          membershipName:'스파크플러스-공유오피스',
          roleGroup:'기본 전체',
          tenant:'스파크플러스',
          type:'관리자',
          team:'플랫폼그룹>기획실>기획팀',
          name:'정상화',
          nickname:'Henry',
          email:'Henry@sparkplus.co',
          membershipStatus:'등록',
          registrationDatetime:'2023-06-04T22:58:43.760170+09:00',
        },
        {
          membershipId:200,
          membershipName:'스파크플러스-공유오피스2',
          roleGroup:'기본 전체2',
          tenant:'스파크플러스2',
          type:'관리자2',
          team:'플랫폼그룹>기획실>기획팀2',
          name:'정상화2',
          nickname:'Henry2',
          email:'Henry@sparkplus.co2',
          membershipStatus:'미등록',
          registrationDatetime:'2025-09-07T22:58:43.760170+09:00',
        }
      ]

      state.list = dataList
      state.count = dataList.length
    },
    setOriginFilter (state, filter) {
      state.originFilter = JSON.parse(JSON.stringify(filter))
    },
  },
  actions: {
    async getList ({ commit }, payload) {
      let response = null
      try {
        response = await this._vm.get(
          `${this._vm.SPARK_ONE_CREW_API_URL}account/membership`,
          payload,
        )
        commit('setResponse', response.data)
        commit('setOriginFilter', payload)
      } catch (error) {
        if (error.response) {
          response = error
        } else {
          throw error
        }
      }
      return response
    },
    async getInfo ({ commit }, payload) {
      let response = null
      try {
        response = await this._vm.get(
          `${this._vm.SPARK_ONE_CREW_API_URL}account/membership/${payload.membershipId}`
        )
      } catch (error) {
        if (error.response) {
          response = error
        } else {
          throw error
        }
      }

      //임시 데이터
      const demo = {
        memberType: 'tenant', //user 또는 tenant
        adminAccess: 'selectOperation', //관리자권한
        notificationOptions: ['notice','settlement'], //알림설정 'notice','contract','settlement'
        tenant: '스파크플러스', //입주사
        team: '본부1 > 실2 > 팀3', //부서
        position: '매니저', //직책
        name: '아이유', //이름
        nickname: '-', //닉네임
        phoneNumber: '01017194685', //휴대폰번호
        email: 'sparkplus@sparkplus.co', //이메일
        registrationDatetime:'2023-06-04T22:58:43.760170+09:00', //등록일시
        authenticationDate: '2023-06-04T22:58:43.760170+09:00', //인증일시
        status: '이용정지', //상태
        membershipName:'스파크플러스_공유오피스_역삼점', //어카운트
        accessGroup:'전체권한', //권한그룹
      }
      return demo
      // return response
    },
  },
}
