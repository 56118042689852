export default {
  install (Vue) {
    Vue.directive('SpResizableSticky', {
      inserted (el, binding, vnode) {
        const nodeName = el.nodeName
        if (nodeName != 'COLGROUP') {
          console.error('Available only for colgroup.')
          return
        }
        const table = el.closest('table')
        if (!table) {
          console.error('The parent table of colgroup could not be found.')
          return
        }
        // :not() = Avoid nested table bugs
        const cols = table.querySelectorAll('col:not(tr col)')
        const ths = table.querySelectorAll('th:not(th th,tr table th)')
        const colNumberToWidth = {}
        let hasVariableWidthCol = false
        try {
          cols.forEach((col, index) => {
            const colWidth = col.style.width
            if (colWidth.slice(-2) == 'px') {
              colNumberToWidth[index] = parseInt(colWidth, 10)
            } else if (colWidth == '') {
              hasVariableWidthCol = true
            } else {
              throw Error('Col element width can only be used in px.')
            }
          })
        } catch (error) {
          console.error(error)
          return
        }
        if (!hasVariableWidthCol) {
          console.error('A colgroup with the SpResizable directive specified must have at least one variable-size col element whose width is not specified.')
          return
        }
        ths.forEach((th, index) => {
          if (Number.isInteger(colNumberToWidth[index])) {
            const resizer = document.createElement('div')
            resizer.style.position = 'absolute'
            resizer.style.top = 0
            resizer.style.bottom = 0
            resizer.style.right = 0
            resizer.style.width = '6px'
            resizer.style.background = 'linear-gradient(90deg, rgba(255,255,255,000) 0%, rgba(200,200,200,0.2) 100%)'
            resizer.style.cursor = 'col-resize'

            resizer.addEventListener('mousedown', (e) => {
              const clientX = (e.clientX) ? e.clientX : e.touches[0].clientX
              const refWidth = parseInt(cols[index].style.width, 10)
              document.body.style.cursor = 'col-resize'
              el.__vueMouseMove__ = (e) => {
                const currentX = (e.clientX) ? e.clientX : e.touches[0]
                const calWidth = refWidth + currentX - clientX
                cols[index].style.width = (calWidth > 6 ? calWidth : '6') + 'px'
              }
              el.__vueMouseUp__ = () => {
                document.body.style.cursor = ''
                window.removeEventListener('mousemove', el.__vueMouseMove__)
                window.removeEventListener('mouseup', el.__vueMouseUp__)
              }
              window.addEventListener('mousemove', el.__vueMouseMove__)
              window.addEventListener('mouseup', el.__vueMouseUp__)
            })
            th.appendChild(resizer)
          }
        })
        const tableth = table.querySelectorAll('thead th')
        tableth.forEach(th => {
          const computedStyle = window.getComputedStyle(th)
          const topValue = computedStyle.top
          th.dataset.top = topValue
        })
        window.addEventListener('scroll', () => {
          const tableOffsetTop = table.offsetTop
          const windowScrollTop = Math.floor(window.scrollY)
          const tableHeight = table.offsetHeight
          const windowHeight = window.innerHeight
          const tabletbodytr = table.querySelector('tbody tr')?.offsetHeight

          if (tableOffsetTop < windowScrollTop + windowHeight && tableOffsetTop + tableHeight - (tabletbodytr * 2) > windowScrollTop) {
            const ths = table.querySelectorAll('thead th')
            ths.forEach(th => {
              const defaultTop = parseInt(th.dataset.top) || 0
              // th가 테이블을 벗어나는 경우
              if (tableOffsetTop < windowScrollTop) {
                // th의 새로운 top 값 계산
                const newTop = windowScrollTop - tableOffsetTop + defaultTop
                th.style.top = newTop + 'px'
              } else {
                // th가 테이블 내에 위치한 경우
                th.style.top = defaultTop + 'px'
              }
            })
          }
        })
      },
      unbind (el) {
        document.body.style.cursor = ''
        window.removeEventListener('mousemove', el.__vueMouseMove__)
        window.removeEventListener('mouseup', el.__vueMouseUp__)
      },
    })
  },
}
