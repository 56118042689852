const getDefaultState = () => {
    return {
      list: [],
      count: 0,
      originFilter: {},
    }
  }
  export default {
    namespaced: true,
    state: getDefaultState(),
    getters: {
      totalPages: state => {
        const limit = state.originFilter?.limit || 20
        const count = state.count || 0
        return Math.ceil(count / limit)
      },
    },
    mutations: {
      resetState (state) {
        Object.assign(state, getDefaultState())
      },
      setResponse (state, data) {
        state.list = data.memberList
        state.count = data.totalCount.count
      },
      setOriginFilter (state, filter) {
        state.originFilter = JSON.parse(JSON.stringify(filter))
      },
    },
    actions: {
      async getMemberList ({ commit }, payload) {
        let response = null
        try {
          response = await this._vm.get(
            `${this._vm.SPARK_ONE_CREW_API_URL}account/membership/member/memberList`,
            payload,
          )
          commit('setResponse', response.data)
          commit('setOriginFilter', payload)
        } catch (error) {
          if (error.response) {
            response = error
          } else {
            throw error
          }
        }
        return response
      },
      //멤버관리 기본정보 추출
      async getMemberInfo ({ commit }, payload) {
        let response = null
        try {
          response = await this._vm.get(
            `${this._vm.SPARK_ONE_CREW_API_URL}account/membership/member/${payload.membershipId}`,
          )
        } catch (error) {
          if (error.response) {
            response = error
          } else {
            throw error
          }
        }
        return response
      },
      //어카운트 멤버관리 그룹별 정보
      async getMemberGroupInfo ({ commit }, payload) {
        let response = null
        try {
          response = await this._vm.get(
            `${this._vm.SPARK_ONE_CREW_API_URL}account/membership/member/group/${payload.membershipId}`,
          )
        } catch (error) {
          if (error.response) {
            response = error
          } else {
            throw error
          }
        }
        return response
      },
      //지점별 조닝에 연결된 qr리스트 추출
      async getBranchZoningList ({commit}){
        let response = null
        try {
          response = await this._vm.get(
            `${this._vm.SPARK_ONE_CREW_API_URL}account/membership/member/branchZoningAssetList`,
          )
        } catch (error) {
          if (error.response) {
            response = error
          } else {
            throw error
          }
        }
        return response
      },
      //상품에 정의된 이용메뉴 설정 값 추출
      async getMenuPermission ({commit}, payload){
        let response = null
        try {
          response = await this._vm.get(
            `${this._vm.SPARK_ONE_CREW_API_URL}product/membership/policy/base/${payload.productId}`,
          )
        } catch (error) {
          if (error.response) {
            response = error
          } else {
            throw error
          }
        }
        return response
      },
      //커스텀 된 이용메뉴 설정 값 추출
      async getCustomMenu ({commit}, payload){
        let response = null
        try {
          response = await this._vm.get(
            `${this._vm.SPARK_ONE_CREW_API_URL}account/membership/member/customMenu/${payload.membershipId}`,
          )
        } catch (error) {
          if (error.response) {
            response = error
          } else {
            throw error
          }
        }
        return response
      },
      //어카운트 멤버십 기본정보, 멤버관리 기본정보, 멤버권한 그룹별 정보 저장 처리
      async postMemberData ({ commit }, payload) {
        let response = null
        try {
          response = await this._vm.post(
            `${this._vm.SPARK_ONE_CREW_API_URL}account/membership/member`,
            payload,
          )
        } catch (error) {
          if (error.response) {
            response = error
          } else {
            throw error
          }
        }
        return response
      },
      //이용메뉴 설정 값 저장 처리
      async postCustomMenu ({ commit }, payload) {
        let response = null
        try {
          response = await this._vm.post(
            `${this._vm.SPARK_ONE_CREW_API_URL}account/membership/member/customMenu`,
            payload,
          )
        } catch (error) {
          if (error.response) {
            response = error
          } else {
            throw error
          }
        }
        return response
      },
      //QR출입 이용정책 설정 값 저장
      async postQrPermissionSetting ({ commit }, payload) {
        let response = null
        try {
          response = await this._vm.post(
            `${this._vm.SPARK_ONE_CREW_API_URL}account/membership/member/qrPermissionSetting`,
            payload,
          )
        } catch (error) {
          if (error.response) {
            response = error
          } else {
            throw error
          }
        }
        return response
      },
      //어카운트 멤버관리 그룹별 QR출입 권한설정에 요일별 이용 시간 정책 정보
      async getSchedule ({commit}, payload){
        let response = null
        try {
          response = await this._vm.get(
            `${this._vm.SPARK_ONE_CREW_API_URL}account/membership/member/schedule`,
            payload
          )
        } catch (error) {
          if (error.response) {
            response = error
          } else {
            throw error
          }
        }
        return response
      },
      //어카운트 멤버그룹별 권한설정에 조닝 정보
      async getZoning ({commit}, payload){
        let response = null
        try {
          response = await this._vm.get(
            `${this._vm.SPARK_ONE_CREW_API_URL}account/membership/member/zoning`,
            payload
          )
        } catch (error) {
          if (error.response) {
            response = error
          } else {
            throw error
          }
        }
        return response
      },
      //방문객초대 이용정책 설정 값 저장
      async postVisitorPermissionSetting ({ commit }, payload) {
        let response = null
        try {
          response = await this._vm.post(
            `${this._vm.SPARK_ONE_CREW_API_URL}account/membership/member/visitorPermissionSetting`,
            payload,
          )
        } catch (error) {
          if (error.response) {
            response = error
          } else {
            throw error
          }
        }
        return response
      },
      async getPolisyInfo ({commit}, payload){
        let response = null
        try {
          response = await this._vm.get(
            `${this._vm.SPARK_ONE_CREW_API_URL}account/membership/member/visitorPolicy`,
            payload
          )
        } catch (error) {
          if (error.response) {
            response = error
          } else {
            throw error
          }
        }
        return response
      },
      //계약에 연결된 상품, 공간, 호실 기준으로 조닝에 연결된 qr데이터 추출
      async getDefultZoning ({commit}, payload){
        let response = null
        try {
          response = await this._vm.get(
            `${this._vm.SPARK_ONE_CREW_API_URL}account/membership/member/defaultZoning/${payload.membershipId}`,
          )
        } catch (error) {
          if (error.response) {
            response = error
          } else {
            throw error
          }
        }
        return response
      },
      //어카운트 멤버십에 연결된 유효한 계약 기준으로 제품 아이디 값 추출
      async getContractProductId ({commit}, payload){
        let response = null
        try {
          response = await this._vm.get(
            `${this._vm.SPARK_ONE_CREW_API_URL}account/membership/member/getContractProductId/${payload.membershipId}`,
          )
        } catch (error) {
          if (error.response) {
            response = error
          } else {
            throw error
          }
        }
        return response
      },
      //어카운트 멤버십 기본 정보만 저장 처리
      async postBaseMembership ({ commit }, payload) {
        let response = null
        try {
          response = await this._vm.post(
            `${this._vm.SPARK_ONE_CREW_API_URL}account/membership/baseMembership`,
            payload,
          )
        } catch (error) {
          if (error.response) {
            response = error
          } else {
            throw error
          }
        }
        return response
      },
      //어카운트 멤버십에 연결된 유효한 계약의 입주사 리스트 정보
      async getContractTenant ({commit}, payload){
        let response = null
        try {
          response = await this._vm.get(
            `${this._vm.SPARK_ONE_CREW_API_URL}account/membership/member/contractTenant/${payload.membershipId}`,
          )
        } catch (error) {
          if (error.response) {
            response = error
          } else {
            throw error
          }
        }
        return response
      },
      async postMemberExcelupload ({commit}, payload){
        let response = null
        try {
          response = await this._vm.post(
            `${this._vm.SPARK_ONE_CREW_API_URL}account/membership/member/postMemberExcelupload`,
            payload,
          )
        } catch (error) {
          if (error.response) {
            response = error
          } else {
            throw error
          }
        }
        return response
      },
      async getDepartmentList ({commit}){
        let response = null
        try {
          response = await this._vm.get(
            `${this._vm.SPARK_ONE_CREW_API_URL}account/membership/member/departmentList`,
          )
        } catch (error) {
          if (error.response) {
            response = error
          } else {
            throw error
          }
        }
        return response
      },
      async getPositionList ({commit}){
        let response = null
        try {
          response = await this._vm.get(
            `${this._vm.SPARK_ONE_CREW_API_URL}account/membership/member/positionList`,
          )
        } catch (error) {
          if (error.response) {
            response = error
          } else {
            throw error
          }
        }
        return response
      },
      async postRegistrationMember ({commit}, payload){
        let response = null
        try {
          response = await this._vm.post(
            `${this._vm.SPARK_ONE_CREW_API_URL}account/membership/member/postRegistrationMember`,
            payload,
          )
        } catch (error) {
          if (error.response) {
            response = error
          } else {
            throw error
          }
        }
        return response
      },
      async getTenantTotalSummaryData ({commit}, payload){
        let response = null
        try {
          response = await this._vm.get(
            `${this._vm.SPARK_ONE_CREW_API_URL}account/membership/member/tenantTotalSummaryData/${payload.membershipId}`,
          )
        } catch (error) {
          if (error.response) {
            response = error
          } else {
            throw error
          }
        }
        return response
      },
      async getTenantSummaryList ({commit}, payload){
        let response = null
        try {
          response = await this._vm.get(
            `${this._vm.SPARK_ONE_CREW_API_URL}account/membership/member/tenantSummaryData/${payload.membershipId}`,
          )
        } catch (error) {
          if (error.response) {
            response = error
          } else {
            throw error
          }
        }
        return response
      },
      async psotMemberLink ({commit}, payload){
        let response = null
        try {
          response = await this._vm.post(
            `${this._vm.SPARK_ONE_CREW_API_URL}account/membership/member/psotMemberLink`,
            payload,
          )
        } catch (error) {
          if (error.response) {
            response = error
          } else {
            throw error
          }
        }
        return response
      },
      async getContractRoomSeatUse ({commit}, payload){
        let response = null
        try {
          response = await this._vm.get(
            `${this._vm.SPARK_ONE_CREW_API_URL}account/membership/member/contractRoomSeatUse/${payload.membershipId}`,
          )
        } catch (error) {
          if (error.response) {
            response = error
          } else {
            throw error
          }
        }
        return response
      },
      async postContractRoomSeatUse ({commit}, payload){
        let response = null
        try {
          response = await this._vm.post(
            `${this._vm.SPARK_ONE_CREW_API_URL}account/membership/member/postContractRoomSeatUse`,
            payload,
          )
        } catch (error) {
          if (error.response) {
            response = error
          } else {
            throw error
          }
        }
        return response
      },
      async postMemberGroupChange ({commit}, payload){
        let response = null
        try {
          response = await this._vm.post(
            `${this._vm.SPARK_ONE_CREW_API_URL}account/membership/member/postMemberGroupChange`,
            payload,
          )
        } catch (error) {
          if (error.response) {
            response = error
          } else {
            throw error
          }
        }
        return response
      },
      async postMemberStatusEnd ({commit}, payload){
        let response = null
        try {
          response = await this._vm.post(
            `${this._vm.SPARK_ONE_CREW_API_URL}account/membership/member/postMemberStatusEnd`,
            payload,
          )
        } catch (error) {
          if (error.response) {
            response = error
          } else {
            throw error
          }
        }
        return response
      },
      async getMemberRegistrationDomain(_context, payload) {
        let response = null
        try {
          response = await this._vm.get(
            `${this._vm.SPARK_ONE_CREW_API_URL}account/membership/tenant/member/memberRegistrationDomain`,
            payload,
          )
        } catch (error) {
          if (error.response) {
            response = error
          } else {
            throw error
          }
        }
        return response
      },
      async updateMemberRegistrationDomain(_context, payload) {
        let response = null
        try {
          response = await this._vm.put(
            `${this._vm.SPARK_ONE_CREW_API_URL}account/membership/member/memberRegistrationDomain`,
            payload
          )
        } catch (error) {
          if (error.response) {
            response = error
          } else {
            throw error
          }
        }
        return response
      },
      async getMemberRegistrationDomainDuplicate(_context, payload) {
        let response = null
        try {
          response = await this._vm.get(
            `${this._vm.SPARK_ONE_CREW_API_URL}account/membership/tenant/member/memberRegistrationDomainDuplicate`,
            payload,
          )
        } catch (error) {
          if (error.response) {
            response = error
          } else {
            throw error
          }
        }
        return response
      }
    },
  }
