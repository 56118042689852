const getDefaultState = () => {
  return {
    list: [],
  }
}
export default {
  namespaced: true,
  state: getDefaultState(),
  getters: {
  },
  mutations: {
    resetState (state) {
      Object.assign(state, getDefaultState())
    },
  },
  actions: {
    // 운영자 권한 등록
    async postPermission (context, payload) {
      let response = null
      try {
        response = await this._vm.post(
          `${this._vm.SPARK_ONE_CREW_API_URL}crew/permission`,
          payload,
        )
      } catch (error) {
        if (error.response) {
          response = error
        } else {
          throw error
        }
      }
      return response
    },
  },
}
