<template>
  <div class="content">
    <datepicker
    placeholder="날짜를 선택해 주세요."
    :show-clear-button="clear"
    :circle="true"
    v-model="day"
    lang="ko"
    v-bind="attrs"
    firstDayOfWeek="sunday"
    class="f-single-datepicker"
    @input="$emit('input', toISOStringDate)"
    @reset="reset"
    ref="dp"
    />
  </div>
</template>

<script>
import 'vue-datepicker-ui/lib/vuedatepickerui.css'
import VueDatepickerUi from 'vue-datepicker-ui'

export default {
  name: 'SpSingleDatepicker',
  inheritAttrs: false,
  props: {
    value:{},
    id:{},
    clear: {
      type: Boolean,
      default: true
    }
  },
  data () {
    return {
      day: null,
      uuid: null,
    }
  },
  created () {
    this.init()
    this.uuid = this.id
    if (this.uuid == '') {
      this.uuid = this.uuidv4()
    }
  },
  mounted () {
    if (this.$refs.dp.$el.firstChild?.firstChild?.placeholder) {
      this.$refs.dp.$el.firstChild.firstChild.id = this.uuid
    }
  },
  computed: {
    toISOStringDate () {
      if (this.day instanceof Date) {
        return this.day.toISOString()
      } else {
        return this.day
      }
    },
    attrs () {
      return this.$attrs
    },
  },
  methods: {
    init () {
      if (this.value instanceof Date) {
        this.day = this.value
      }
    },
    setDate (date) {
      this.day = date
      this.$emit('input', this.day)
    },
    reset () {
      this.setDate(null)
    },
  },
  watch: {
    value (newValue, prevValue) {
      if (JSON.stringify(newValue) === JSON.stringify(prevValue)) return
      this.day = this.value
    },
  },
  components: {
    Datepicker: VueDatepickerUi,
  },
}
</script>

<style scoped>
.content{
  display: inline-block;
  vertical-align: top;
  margin: 0;
}
</style>
<style lang="scss">
.v-calendar.f-single-datepicker {
  margin: 4px 2.5px;
}
.v-calendar .number {
  margin: -2px 0 0 -5px;
}
.v-calendar button {
  border-radius: 0;
  margin: 0;
  line-height: 10px;
  font-size: 20px !important;
}
.v-calendar.f-single-datepicker .content {
  transform: translate(-12%, -20%) scale(73%);
}
.v-calendar.f-single-datepicker {
  display :inline-block;
  .input-field {
    min-width: 135px;

    input {
      width: 135px;
      color: #000;
      border-radius: 5px;
      border-color: #ddd;
      height: 22px;
    }
    input:focus {
      border-color: var(--theme-primary-color);
      animation: shadow 0.1s ease-in-out forwards;
    }
    @keyframes shadow {
      to {
        box-shadow: 0 0 6px var(--theme-primary-color);
      }
    }
  }
}
</style>
