const getDefaultState = () => {
  return {
    list: null,
    count: 0,
    originFilter: {},
  }
}
export default {
  namespaced: true,
  state: getDefaultState(),
  getters: {
    totalPages: state => {
      const limit = state.originFilter?.limit || 20
      const count = state.count || 0
      return Math.ceil(count / limit)
    },
  },
  mutations: {
    resetState (state) {
      Object.assign(state, getDefaultState())
    },
    setResponse (state, data) {
      //임시데이터
      const dataList = [
        {
          memberId:2,
          memberIdName:'spark',
          name:'김스플',
          gendeType:'남성',
          age:'30',
          phoneNumber:'01012345678',
          spaceProduct:'스플라운지 싱글 패스-부산점',
          solutionProduct:'이것은 하드코딩 데이터입니다 실제 통신 데이터 아님!!',
          registrationDate:'2023-06-04T22:58:43.760170+09:00',
          membershipStatus:'정상'
        },
        {
          memberId:2,
          memberIdName:'spark',
          name:'김스플',
          gendeType:'남성',
          age:'30',
          phoneNumber:'01012345678',
          spaceProduct:'스플라운지 싱글 패스-부산점',
          solutionProduct:'이것은 하드코딩 데이터입니다 실제 통신 데이터 아님!!',
          registrationDate:'2023-06-04T22:58:43.760170+09:00',
          membershipStatus:'정상'
        }
      ]

      state.list = dataList
      state.count = dataList.length
    },
    setOriginFilter (state, filter) {
      state.originFilter = JSON.parse(JSON.stringify(filter))
    },
  },
  actions: {
    async getList ({ commit }, payload) {
      let response = null
      try {
        response = await this._vm.get(
          `${this._vm.SPARK_ONE_CREW_API_URL}account/membership`,
          payload,
        )
        commit('setResponse', response.data)
        commit('setOriginFilter', payload)
      } catch (error) {
        if (error.response) {
          response = error
        } else {
          throw error
        }
      }
      return response
    },
    async getInfo ({ commit }, payload) {
      let response = null
      try {
        response = await this._vm.get(
          `${this._vm.SPARK_ONE_CREW_API_URL}account/membership/${payload.membershipId}`
        )
      } catch (error) {
        if (error.response) {
          response = error
        } else {
          throw error
        }
      }

      //임시 데이터
      const demo = {
        memberIdName:'sparkplus12345', //아이디
        memberPassword:'1004', //비밀번호
        registrationDatetime:'2023-06-04T22:58:43.760170+09:00', //가입일시
        lastLoginDateTime:'2023-06-04T22:58:43.760170+09:00', //최근접속일시
        name: '손오공', //이름
        birthdate: '2023-06-04T22:58:43.760170+09:00', //생년월일
        genderType: '남성', //성별
        phoneNumber: '01012345678', //휴대폰번호
        authenticationDate: '2023-06-04T22:58:43.760170+09:00', //인증일시
        email: 'sparkplus@sparkplus.co', //이메일
        isAuthenticated: '인증', //인증여부
        status:'정상', //상태
        termsAgreementList:[
          {
            agreement1:'2023-06-04T22:58:43.760170+09:00', //서비스 이용 약관
            agreement1Version:'1.0.0', //버전
            agreement2:'2023-06-04T22:58:43.760170+09:00', //위치기반 서비스 이용 약관
            agreement2Version:'2.0.0', //버전
            agreement3:'2023-06-04T22:58:43.760170+09:00', //개인 정보 처리 방침
            agreement3Version:'3.0.0', //버전
            marketing:'2023-06-04T22:58:43.760170+09:00', //마케팅 정보 수신 동의
            marketingStatus:'5(철회)', //상태
            agreement4:'동의', //제3자 개인정보 제공 동의
          }
        ],
        deviceList:[
          {
            deviceName:'iphone pro 13', //디바이스
            deviceOs:'ios 15.6.1', //OS
            deviceApp:'SPARKPLUS', //APP
            deviceAppVer:'2.0.0', //APP Ver
            locationAccess:'허용', //위치 서비스 권한
            firstLoginDatetime:'2023-06-04T22:58:43.760170+09:00', // 앱 최초 로그인 일시
          }
        ],
        membershipList:[
          {
            product:'공유 오피스 역삼점 201호',
            contractType:'독립계약',
            email:'aa@aaa.co',
            membershipPeriod:[
              "2023-06-10T15:00:00.000Z",
              "2023-06-10T15:00:00.000Z"
            ],
            contractId:'ID1234567890',
            businessEntity:'현대자동차',
            status:'실행',
            query:true,
          },
          {
            product:'오피스솔루션',
            contractType:'복합계약',
            email:'bb@bbb.co',
            membershipPeriod:[
              "2023-06-10T15:00:00.000Z",
              "2023-06-10T15:00:00.000Z"
            ],
            contractId:'ID0987654321',
            businessEntity:'쉐보레',
            status:'일시중지',
            query:false,
          }
        ],
      }
      return demo
    },
  }
}