const getDefaultState = () => {
  return {
    list: null,
    count: 0,
    totalPage: 0,
    categoryList: {},
    originFilter: {},
    applyList: [],
    applyCount: 0,
    branchList: [],
  }
}
export default {
  namespaced: true,
  state: getDefaultState(),
  getters: {
    totalPages: (state) => {
      const limit = state.originFilter?.limit || 10
      const count = state.count || 0
      return Math.ceil(count / limit)
    },
    applyTotalPages: (state) => {
      const limit = state.originFilter?.limit || 10
      const count = state.applyCount || 0
      return Math.ceil(count / limit)
    },
  },
  mutations: {
    resetState (state) {
      Object.assign(state, getDefaultState())
    },
    setResponse (state, data) {
      state.list = data.corporateList
      state.count = data.totalCount.cnt
    },
    setOriginFilter (state, filter) {
      state.originFilter = JSON.parse(JSON.stringify(filter))
    },
    setCategoryInfo (state, data) {
      state.categoryList = data || {}
    },
    setApplyResponse (state, data) {
      state.applyList = data.corporateApplyList
      state.applyCount = data.totalCount.cnt
    },
    setBranch (state, data) {
      state.branchList[0] = data.branchList
      // console.log(state.branchList)
    },
  },
  actions: {
    async setCorporate ({ dispatch }, payload) {
      let response = null
      try {
        response = await this._vm.post(
          `${this._vm.SPARK_ONE_CREW_API_URL}corporate/service/post`,
          payload,
        )
      } catch (error) {
        if (error.response) {
          response = error
        } else {
          throw error
        }
      }
      return response
    },
    async getCategoryInfo (context) {
      context.commit('setCategoryInfo', {})

      return new Promise((resolve, reject) => {
        this._vm
          .get(`${this._vm.SPARK_ONE_CREW_API_URL}corporate/service/category`)
          .then((response) => {
            context.commit('setCategoryInfo', response)
            resolve(response)
          })
          .catch((error) => reject(error))
      })
    },
    async getCorporateList ({ commit }, payload) {
      let response = null
      try {
        response = await this._vm.get(
          `${this._vm.SPARK_ONE_CREW_API_URL}corporate`,
          payload,
        )
        commit('setResponse', response.data)
        commit('setOriginFilter', payload)
      } catch (error) {
        if (error.response) {
          response = error
        } else {
          throw error
        }
      }
      return response
    },
    async getCorporateAllList ({ commit }, payload) {
      let response = null
      try {
        // if(payload['displayGoodsBranch'].length > 0){
        //   let branch_list = ""
        //   payload['displayGoodsBranch'].forEach((item) => {
        //     branch_list += item.id+","
        //   })
        //   branch_list = branch_list.slice(0,-1)
        //   payload['branchList'] = branch_list
        // }else{
        //   payload['branchList'] = ''
        // }

        response = await this._vm.get(
          `${this._vm.SPARK_ONE_CREW_API_URL}corporateAll`,
          payload,
        )
        commit('setResponse', response.data)
        commit('setOriginFilter', payload)
      } catch (error) {
        if (error.response) {
          response = error
        } else {
          throw error
        }
      }
      return response
    },
    async getCorporateApplyList ({ commit }, payload) {
      let response = null
      try {
        response = await this._vm.get(
          `${this._vm.SPARK_ONE_CREW_API_URL}corporateService/getCorporateApplyList`,
          payload,
        )
        commit('setApplyResponse', response.data)
        commit('setOriginFilter', payload)
      } catch (error) {
        if (error.response) {
          response = error
        } else {
          throw error
        }
      }
      return response
    },

    async getCorporateApplyListExcel ({ commit }, payload) {
      let response = null
      try {
        response = await this._vm.get(
          `${this._vm.SPARK_ONE_CREW_API_URL}corporateService/getCorporateApplyList/excel`,
          payload,
        )
      } catch (error) {
        if (error.response) {
          response = error
        } else {
          throw error
        }
      }
      return response
    },
    async corporateInfo ({ commit }, payload) {
      let response = null
      try {
        response = await this._vm.get(
          `${this._vm.SPARK_ONE_CREW_API_URL}corporate/${payload.goodsId}`,
        )
      } catch (error) {
        if (error.response) {
          response = error
        } else {
          throw error
        }
      }
      return response
    },
    async corporateOrderUpdate ({ commit }, payload) {
      let response = null
      try {
        response = await this._vm.post(
          `${this._vm.SPARK_ONE_CREW_API_URL}corporate/orderingUpdate`,
          payload,
        )
      } catch (error) {
        if (error.response) {
          response = error
        } else {
          throw error
        }
      }
      return response
    },
    async putCorporate ({ commit }, payload) {
      let response = null
      try {
        response = await this._vm.put(
          `${this._vm.SPARK_ONE_CREW_API_URL}corporate/${payload.goodsId}`,
          payload,
        )
      } catch (error) {
        if (error.response) {
          response = error
        } else {
          throw error
        }
      }
      return response
    },
    async getBranchList ({ commit }) {
      let response = null
      try {
        response = await this._vm.get(
          `${this._vm.SPARK_ONE_CREW_API_URL}corporate/service/getBranchList`,
        )
        // console.log(response.data)
        // commit('setApplyResponse', response.data)
        commit('setBranch', response.data)
      } catch (error) {
        if (error.response) {
          response = error
        } else {
          throw error
        }
      }
      return response
    },
    async getAccountExcelList ({ commit }, payload) {
      let response = null
      try {
        response = await this._vm.get(
          `${this._vm.SPARK_ONE_CREW_API_URL}corporate/service/excel`,
          payload,
        )
      } catch (error) {
        if (error.response) {
          response = error
        } else {
          throw error
        }
      }
      return response
    },
    async deleteCorporate ({ dispatch }, payload) {
      let response = null
      try {
        response = await this._vm.delete(
          `${this._vm.SPARK_ONE_CREW_API_URL}corporate/service/delete/${payload.goodsId}`,
          payload,
        )
      } catch (error) {
        if (error.response) {
          response = error
        } else {
          throw error
        }
      }
      return response
    },
  },
}
