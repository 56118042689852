const getDefaultState = () => {
  return {
    info: {},
    list: null,
    count: 0,
    totalPage: 0,
    originFilter: {},
  }
}
export default {
  namespaced: true,
  state: getDefaultState(),
  getters: {
    totalPages: state => {
      const limit = state.originFilter?.limit || 20
      const count = state.count || 0
      return Math.ceil(count / limit)
    },
  },
  mutations: {
    resetState (state) {
      Object.assign(state, getDefaultState())
    },
    setListResponse (state, data) {
      state.list = data.branchNoticeList
      state.count = data.branchNoticeCount
    },
    setDataInfo (state, data) {
      state.info = data || {}
    },
    setDataCount (state, data) {
      state.count = data || 0
    },
    setDataTotalPage (state, data) {
      state.totalPage = data || 0
    },
    setOriginFilter(state, filter) {
      state.originFilter = JSON.parse(JSON.stringify(filter))
    },
  },
  actions: {
    async getBranchNoticeList ({ commit }, payload) {
      let response = null
      try {
        response = await this._vm.get(`${this._vm.SPARK_ONE_CREW_API_URL}operator/branch-notice`, payload)
        commit('setListResponse', response.data)
        commit('setOriginFilter', payload)
      } catch (error) {
        if (error.response) {
          response = error
        } else {
          throw error
        }
      }
      return response
    },
    getBranchNoticeInfo ({ commit }, payload) {
      let response = null
      try {
        response = this._vm.get(`${this._vm.SPARK_ONE_CREW_API_URL}operator/branch-notice/${payload.branchNoticeId}`)
      } catch (error) {
        if (error.response) {
          response = error
        } else {
          throw error
        }
      }
      return response
    },
    setBranchNotice (_context, payload) {
      let response = null
      try {
        response = this._vm.post(
          `${this._vm.SPARK_ONE_CREW_API_URL}operator/branch-notice`,
          payload
        )
      } catch (error) {
        if (error.response) {
          response = error
        } else {
          throw error
        }
      }
      return response
    },
    putBranchNotice (_context, payload) {
      let response = null
      try {
        response = this._vm.put(
          `${this._vm.SPARK_ONE_CREW_API_URL}operator/branch-notice/${payload.branchNoticeId}`,
          payload
        )
      } catch (error) {
        if (error.response) {
          response = error
        } else {
          throw error
        }
      }
      return response
    },
    delBranchNotice (_context, payload) {
      let response = null
      try {
        response = this._vm.delete(
          `${this._vm.SPARK_ONE_CREW_API_URL}operator/branch-notice/${payload.branchNoticeId}`,
          payload
        )
      } catch (error) {
        if (error.response) {
          response = error
        } else {
          throw error
        }
      }
      return response
    },
  },
}
