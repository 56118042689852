const getDefaultState = () => {
  return {
    branches: [],
    branchFloor: [],
    assets: [],
    stats: {},
    originFilter: {},
    roomContracts: [],
  }
}
export default {
  namespaced: true,
  state: getDefaultState(),
  getters: {
    branches: (state) => state.branches,
    branchFloor: (state) => state.branchFloor,
    assets: (state) => state.assets,
    stats: (state) => state.stats,
    roomContracts: (state) => state.roomContracts,
  },
  mutations: {
    resetState (state) {
      Object.assign(state, getDefaultState())
    },
    setResponse (state, data) {
      state.assets = data.assets
      state.branchFloor = data.branchFloor
    },
    setOriginFilter (state, filter) {
      state.originFilter = JSON.parse(JSON.stringify(filter))
    },
    setBranchFloors (state, data) {
      state.branches = data.branches
    },
    setStats (state, data) {
      state.stats = data.stats
    },
    setRoomContracts (state, data) {
      state.roomContracts = data.contracts
    },
  },
  actions: {
    async getAssets ({ commit }, payload) {
      let response = null
      try {
        response = await this._vm.get(
          `${this._vm.SPARK_ONE_CREW_API_URL}contract/branch/${payload.branchId}/floor/${payload.floorType}/assets`,
        )
        commit('setResponse', response.data)
        // commit('setOriginFilter', payload)
      } catch (error) {
        if (error.response) {
          response = error
        } else {
          throw error
        }
      }
      return response
    },
    async getStats ({ commit }, payload) {
      let response = null
      try {
        response = await this._vm.get(
          `${this._vm.SPARK_ONE_CREW_API_URL}contract/branch/${payload.branchId}/floor/${payload.floorType}/stats`,
        )
        commit('setStats', response.data)
      } catch (error) {
        if (error.response) {
          response = error
        } else {
          throw error
        }
      }
      return response
    },
    async getBranchFloors ({ commit }) {
      let response = null
      try {
        response = await this._vm.get(
          `${this._vm.SPARK_ONE_CREW_API_URL}contract/meta/branch/floors`,
        )
        commit('setBranchFloors', response.data)
      } catch (error) {
        if (error.response) {
          response = error
        } else {
          throw error
        }
      }
      return response
    },
    async getRoomContracts ({ commit }, payload) {
      let response = null
      try {
        response = await this._vm.get(
          `${this._vm.SPARK_ONE_CREW_API_URL}contract/room/${payload.roomId}`,
        )
        commit('setRoomContracts', response.data)
      } catch (error) {
        if (error.response) {
          response = error
        } else {
          throw error
        }
      }
      return response
    },
  },
}
