const getDefaultState = () => {
  return {
    list: null,
  }
}
export default {
  namespaced: true,
  state: getDefaultState(),
  getters: {
  },
  mutations: {
    resetState (state) {
      Object.assign(state, getDefaultState())
    },
    setResponse (state, data) {
      state.list = data.grantGroups
    },
  },
  actions: {
    async getMembershipGrantList ({ commit }, payload) {
      let response = null
      try {
        response = await this._vm.get(
          `${this._vm.SPARK_ONE_CREW_API_URL}account/memberships/grant-groups`,
          payload,
        )
        commit('setResponse', response.data)
      } catch (error) {
        if (error.response) {
          response = error
        } else {
          throw error
        }
      }
      return response
    },
  },
}
