const getDefaultState = () => {
  return {
    info: {},
    list: null,
    count: 0,
    totalPage: 0,
  }
}
export default {
  namespaced: true,
  state: getDefaultState(),
  getters: {
    totalPages: state => {
      const limit = state.originFilter?.limit || 20
      const count = state.count || 0
      return Math.ceil(count / limit)
    },
  },
  mutations: {
    resetState (state) {
      Object.assign(state, getDefaultState())
    },
    setDataInfo (state, data) {
      state.info = data || {}
    },
    setDataList (state, data) {
      state.list = data.termsList || []
      state.count = data.termsCount || 0
    },
    setDataCount (state, data) {
      state.count = data || 0
    },
    setDataTotalPage (state, data) {
      state.totalPage = data || 0
    },
    setOriginFilter(state, filter) {
      state.originFilter = JSON.parse(JSON.stringify(filter))
    },
  },
  actions: {
    async getUserTermsList ({ commit }, payload) {
      let response = null
      try {
        response = await this._vm.get(`${this._vm.SPARK_ONE_CREW_API_URL}operator/user-terms`, payload)
        commit('setDataList', response.data)
        commit('setOriginFilter', payload)
      } catch (error) {
        if (error.response) {
          response = error
        } else {
          throw error
        }
      }
      return response
    },
    async getUserTermsInfo ({ commit }, payload) {
      let response = null
      try {
        response = await this._vm.get(`${this._vm.SPARK_ONE_CREW_API_URL}operator/user-terms/${payload.termsId}`)
        commit('setDataInfo', response.data)
      } catch (error) {
        if (error.response) {
          response = error
        } else {
          throw error
        }
      }
      return response
    },
    async setUserTerms (_context, payload) {
      let response = null
      try {
        response = await this._vm.post(`${this._vm.SPARK_ONE_CREW_API_URL}operator/user-terms`, payload)
      } catch (error) {
        if (error.response) {
          response = error
        } else {
          throw error
        }
      }
      return response
    },
    async putUserTerms (_context, payload) {
      let response = null
      try {
        response = await this._vm.put(`${this._vm.SPARK_ONE_CREW_API_URL}operator/user-terms/${payload.termsId}`, payload)
      } catch (error) {
        if (error.response) {
          response = error
        } else {
          throw error
        }
      }
      return response
    },
  },
}
