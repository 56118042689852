export default [
  {
    path: '/account/account',
    name: 'AccountListPage',
    meta: {
      requiresAuth: true,
      fullScreen: false,
    },
    component: () => import('@/components/account/AccountListPage.vue'),
  },
  {
    path: '/account/account/regist',
    name: 'AccountRegistPage',
    meta: {
      requiresAuth: true,
      fullScreen: false,
    },
    component: () => import('@/components/account/AccountRegistPage.vue'),
  },
  {
    path: '/account/account/:id/edit',
    name: 'AccountEditPage',
    meta: {
      requiresAuth: true,
      fullScreen: false,
    },
    component: () => import('@/components/account/AccountRegistPage.vue'),
  },
  {
    path: '/account/account-member',
    name: 'AccountMemberPage',
    meta: {
      requiresAuth: true,
      fullScreen: false,
    },
    component: () =>
      import('@/components/account/member/AccountMemberPage.vue'),
  },
  {
    path: '/account/account-member/:id/edit',
    name: 'AccountMemberEditPage',
    meta: {
      requiresAuth: true,
      fullScreen: false,
    },
    component: () =>
      import('@/components/account/member/AccountMemberEditPage.vue'),
  },
  {
    path: '/contract/information',
    name: 'ContractListPage',
    meta: {
      requiresAuth: true,
      fullScreen: false,
    },
    component: () => import('@/components/contract/ContractListPage.vue'),
  },
  {
    path: '/contract/monthly-payment-cancel',
    name: 'MonthlyPaymentCancelPage',
    meta: {
      requiresAuth: true,
      fullScreen: false,
    },
    component: () => import('@/components/contract/MonthlyPaymentCancelPage.vue'),
  },
  {
    path: '/contract/space',
    name: 'ContractSpacePage',
    meta: {
      requiresAuth: true,
      fullScreen: false,
    },
    component: () => import('@/components/contract/SpacePage.vue'),
  },
  {
    path: '/active/contract/space/',
    name: 'ActiveContractSpacePage',
    meta: {
      requiresAuth: true,
      fullScreen: false,
    },
    component: () => import('@/components/contract/SpaceActivePage.vue'),
  },
]
