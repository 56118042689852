const getDefaultState = () => {
    return {
        count: 0,
        originFilter: {}
    }
}
export default {
    namespaced: true,
    state: getDefaultState(),
    getters: {
        totalPages: state => {
            const limit = state.originFilter?.limit || 20
            const count = state.count || 0
            return Math.ceil(count / limit)
        },
    },
    mutations: {
        resetState (state) {
            Object.assign(state, getDefaultState())
        },
        setOriginFilter (state, filter) {
            state.originFilter = JSON.parse(JSON.stringify(filter))
        },
    },
    actions: {
        async getDistributionData (_context, depositId) {
            let response = null
            try {
                response = await this._vm.get(
                    `${this._vm.SPARK_ONE_CREW_API_URL}settlement/v2/distribution/account-deposit/distribution/${depositId}`
                )
            } catch (error) {
                if (error.response) {
                    response = error
                } else {
                    throw error
                }
            }
            return response
        },
        async putDistributionData (_context, payload) {
            let response = null
            try {
                response = await this._vm.put(`${this._vm.SPARK_ONE_CREW_API_URL}settlement/v2/distribution/account-deposit/distribution/${payload.accountDepositId}`, payload)
            } catch (error) {
                if (error.response) {
                    response = error
                } else {
                    throw error
                }
            }
            return response
        },
        async getDistributionHistoryList (_context, billingId) {
            let response = null
            try {
                response = await this._vm.get(
                    `${this._vm.SPARK_ONE_CREW_API_URL}settlement/v2/distribution/distribution-history/${billingId}`
                )
            } catch (error) {
                if (error.response) {
                    response = error
                } else {
                    throw error
                }
            }
            return response
        },
        async getAccountDepositRefund (_context, accountDepositId) {
            let response = null
            try {
                response = await this._vm.get(
                    `${this._vm.SPARK_ONE_CREW_API_URL}settlement/v2/distribution/account-deposit-refund/${accountDepositId}`
                )
            } catch (error) {
                if (error.response) {
                    response = error
                } else {
                    throw error
                }
            }
            return response   
        },
        async postAccountDepositRefund (_context, payload) {
            let response = null
            try {
                response = await this._vm.post(
                    `${this._vm.SPARK_ONE_CREW_API_URL}settlement/v2/distribution/account-deposit-refund/${payload.accountDepositId}`,
                    payload
                )
            } catch (error) {
                if (error.response) {
                    response = error
                } else {
                    throw error
                }
            }
            return response   
        }
    }
}