const getDefaultState = () => {
  return {
    branchList: [],
    goodsList: [],
    goodsOneTimeList: [],
    tenantListBusiness: [],
    tenantListPersonal: [],
    contractListPersonal: [],
    accountList: [],
    versionList: [],
  }
}
export default {
  namespaced: true,
  state: getDefaultState(),
  getters: {
  },
  mutations: {
    resetState (state) {
      Object.assign(state, getDefaultState())
    },
    setBranchResponse (state, data) {
      state.branchList = data.branchList
    },
    setGoodsResponse (state, data) {
      state.goodsList = data.goodsList
    },
    setGoodsOneTimeResponse (state, data) {
      state.goodsOneTimeList = data.goodsList
    },
    setTenantBusinessResponse (state, data) { // 입주사 - 사업자
      state.tenantListBusiness = data.tenantList
    },
    setTenantPersonalResponse (state, data) { // 입주사 - 개인
      state.tenantListPersonal = data.tenantList
    },
    setAccountResponse (state, data) {
      state.accountList = data
    },
    setInvoiceResponse (state, data) {
      state.versionList = data.invoiceVersions
    },
  },
  actions: {
    // 정산 > 공통 필터 입주사(사업자/개인) 조회 API
    async getFilterTenant ({ commit }, type) {
      let response = null
      const payload = {
        tenantType: type,
      }
      try {
        response = await this._vm.get(`${this._vm.SPARK_ONE_CREW_API_URL}settlement/common/filter/tenant`, payload)
        if (type === 'business') { // 사업자
          commit('setTenantBusinessResponse', response.data)
        } else if (type === 'personal') { // 개인
          commit('setTenantPersonalResponse', response.data)
        }

      } catch (error) {
        if (error.response) {
          response = error
        } else {
          throw error
        }
      }
      return response
    },
    // 정산 등록/조회 시 어카운트 리스트 조회 API
    async getFilterAccount ({ commit }, id) {
      let response = null
      const payload = { tenantId: id }
      try {
        // response = await this._vm.get(`${this._vm.SPARK_ONE_CREW_API_URL}settlement/common/filter/account`, payload)
        response = await this._vm.get(`${this._vm.SPARK_ONE_CREW_API_URL}settlement/v2/invoice_release/filter/membership`, payload)
        commit('setAccountResponse', response.data)
      } catch (error) {
        if (error.response) {
          response = error
        } else {
          throw error
        }
      }
      return response
    },
    async getInvoiceVersion ({ commit }, payload) {
      let response = null
      try {
        response = await this._vm.get(`${this._vm.SPARK_ONE_CREW_API_URL}settlement/v2/invoice_release/filter/invoice-version`, payload)
        commit('setInvoiceResponse', response.data)
      } catch (error) {
        if (error.response) {
          response = error
        } else {
          throw error
        }
      }
      return response
    },
    async getInvoiceList ({ commit }, payload) {
      let response = null
      try {
        response = await this._vm.get(`${this._vm.SPARK_ONE_CREW_API_URL}settlement/v2/invoice_release`, payload)
      } catch (error) {
        if (error.response) {
          response = error
        } else {
          throw error
        }
      }
      return response
    },
    async getEmailSendInfo ({ commit }, payload) {
      let response = null
      try {
        response = await this._vm.get(`${this._vm.SPARK_ONE_CREW_API_URL}settlement/v2/invoice_release/email_send_info`, payload)
      } catch (error) {
        if (error.response) {
          response = error
        } else {
          throw error
        }
      }
      return response
    },
  },
}
