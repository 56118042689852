export default {
  namespaced: true,
  state: {
    info: {},
    list: [],
    count: 0,
    totalPage: 0,
  },
  getters: {

  },
  mutations: {
    resetState (state) {
      Object.assign(state.count, 0)
      Object.assign(state.list, [])
    },
    setDataInfo (state, data) {
      state.info = data || {}
    },
    setDataList (state, data) {
      state.list = data || {}
    },
    setDataCount (state, data) {
      state.count = data || 0
    },
    setDataTotalPage (state, data) {
      state.totalPage = data || 0
    },
  },
  actions: {
    getArticleCommentList (context, payload) {

      return new Promise((resolve, reject) => {

        this._vm.get(`${this._vm.SPARK_ONE_CREW_API_URL}contents/article-comment`, payload)
          .then((response) => {
            context.commit('setDataList', response.data.articleCommentList || [])
            context.commit('setDataCount', response.data.articleCommentCount || 0)

            if ( payload && typeof payload.limit !== 'undefined' )
              context.commit('setDataTotalPage', Math.ceil((response.data.articleCommentCount || 0)/(payload.limit)))

            resolve(response)
          })
          .catch((error) => reject(error))

      })

    },
    getArticleCommentInfo (context, payload) {
      context.commit('setDataInfo', {})

      return new Promise((resolve, reject) => {

        this._vm.get(`${this._vm.SPARK_ONE_CREW_API_URL}contents/article-comment/${payload.articleCommentId}`)
          .then(response => {
            context.commit('setDataInfo', response.data.articleCommentInfo)
            resolve(response)
          })
          .catch(error => reject(error))

      })

    },
    setArticleComment (context, payload) {

      return new Promise((resolve, reject) => {

        this._vm.post(`${this._vm.SPARK_ONE_CREW_API_URL}contents/article-comment`, payload)
          .then((response) => {
            resolve(response)
          })
          .catch((error) => reject(error))

      })

    },
    putArticleComment (context, payload) {

      return new Promise((resolve, reject) => {

        this._vm.put(`${this._vm.SPARK_ONE_CREW_API_URL}contents/article-comment/${payload.articleCommentId}`, payload)
          .then(response => {
            resolve(response)
          })
          .catch(error => reject(error))

      })

    },
    delArticleComment (context, payload) {

      return new Promise((resolve, reject) => {

        this._vm.delete(`${this._vm.SPARK_ONE_CREW_API_URL}contents/article-comment/${payload.articleCommentId}`, payload)
          .then(response => {
            resolve(response || {})
          })
          .catch(error => reject(error))

      })

    },
  },
}
