const getDefaultState = () => {
  return {
    list: [],
    menu_list: {},
    count: 0,
    originFilter: {},
    grant: {},
    parentMenuIdList: [],
    childFunctionIdList: [],
    updateHistoryOriginFilter: {},
    grantAdminOriginFilter: {},
    updateHistorynList: [],
    updateHistoryTotalCount: 0,
    grantAdminList: [],
    grantAdminTotalCount: 0,
  }
}
export default {
  namespaced: true,
  state: getDefaultState(),
  getters: {
    totalPages: state => {
      const limit = state.originFilter?.limit || 50
      const count = state.count || 0
      return Math.ceil(count / limit)
    },
    updateHistoryTotalPages: state => {
      const limit = state.updateHistoryOriginFilter?.limit || 50
      const count = state.updateHistoryTotalCount || 0
      return Math.ceil(count / limit)
    },
    grantAdminTotalPages: state => {
      const limit = state.grantAdminOriginFilter?.limit || 50
      const count = state.grantAdminTotalCount || 0
      return Math.ceil(count / limit)
    },
  },
  mutations: {
    updateHistoryResetState (state) {
      state.updateHistorynList = []
      state.updateHistoryTotalCount = 0
    },
    grantAdminResetState (state) {
      state.grantAdminList = []
      state.grantAdminTotalCount = 0
    },
    resetState (state) {
      Object.assign(state, getDefaultState())
    },
    setResponse (state, data) {
      state.list = data.permissionList
      state.count = data.totalCount
    },
    setResponseMenu (state, data) {
      state.menu_list = data.menuList
    },
    setResponsePermission (state, data) {
      state.grant = data.grant
      state.childFunctionIdList = data.childFunctionIdList
    },
    setResponseUpdateHistory (state, data) {
      state.updateHistorynList = data.updateHistorynList
      state.updateHistoryTotalCount = data.updateHistoryTotalCount
    },
    setResponseGrantAdmin (state, data) {
      state.grantAdminList = data.grantAdminList
      state.grantAdminTotalCount = data.grantAdminTotalCount
    },
    setOriginFilter (state, filter) {
      state.originFilter = JSON.parse(JSON.stringify(filter))
    },
    setUpdateHistoryOriginFilter (state, filter) {
      state.updateHistoryOriginFilter = JSON.parse(JSON.stringify(filter))
    },
    setGrantAdminOriginFilter (state, filter) {
      state.grantAdminOriginFilter = JSON.parse(JSON.stringify(filter))
    },
  },
  actions: {
    async getList ({ commit }, payload) {
      let response = null
      try {
        response = await this._vm.get(
          `${this._vm.SPARK_ONE_CREW_API_URL}crew/permission`,
          payload,
        )
        commit('setResponse', response.data)
        commit('setOriginFilter', payload)
      } catch (error) {
        if (error.response) {
          response = error
        } else {
          throw error
        }
      }
      return response
    },
    async getPermissionMenu ({ commit }, payload) {
      let response = null
      try {
        response = await this._vm.get(
          `${this._vm.SPARK_ONE_CREW_API_URL}crew/permission-menu`,
          payload,
        )
        commit('setResponseMenu', response.data)
        commit('setOriginFilter', payload)
      } catch (error) {
        if (error.response) {
          response = error
        } else {
          throw error
        }
      }
      return response
    },
    // 권한 등록
    async postPermission (context, payload) {
      let response = null
      try {
        response = await this._vm.post(
          `${this._vm.SPARK_ONE_CREW_API_URL}crew/permission`,
          payload,
        )
      } catch (error) {
        if (error.response) {
          response = error
        } else {
          throw error
        }
      }
      return response
    },
    // 권한 수정
    async putPermission (context, payload) {
      let response = null
      try {
        response = await this._vm.put(
          `${this._vm.SPARK_ONE_CREW_API_URL}crew/permission`,
          payload,
        )
      } catch (error) {
        if (error.response) {
          response = error
        } else {
          throw error
        }
      }
      return response
    },
    async getPermissionFunction ({ commit }, payload) {
      let response = null
      try {
        response = await this._vm.get(
          `${this._vm.SPARK_ONE_CREW_API_URL}crew/permission/${payload.grantId}`,
          {},
        )
        commit('setResponsePermission', response.data)
        commit('setOriginFilter', payload)
      } catch (error) {
        if (error.response) {
          response = error
        } else {
          throw error
        }
      }
      return response
    },
    async getUpdateHistory ({ commit }, payload) {
      let response = null
      try {
        response = await this._vm.get(
          `${this._vm.SPARK_ONE_CREW_API_URL}crew/update-history`,
          payload,
        )
        commit('setResponseUpdateHistory', response.data)
        commit('setUpdateHistoryOriginFilter', payload)
      } catch (error) {
        if (error.response) {
          response = error
        } else {
          throw error
        }
      }
      return response
    },
    async getGrantAdmin ({ commit }, payload) {
      let response = null
      try {
        response = await this._vm.get(
          `${this._vm.SPARK_ONE_CREW_API_URL}crew/grant-admin`,
          payload,
        )
        commit('setResponseGrantAdmin', response.data)
        commit('setGrantAdminOriginFilter', payload)
      } catch (error) {
        if (error.response) {
          response = error
        } else {
          throw error
        }
      }
      return response
    },



  },
}
