const getDefaultState = () => {
    return {
      list: null,
      count: 0,
      originFilter: {},
      isNonCounselContract: null,
    }
  }
  export default {
    namespaced: true,
    state: getDefaultState(),
    getters: {
      totalPages: state => {
        const limit = state.originFilter?.limit || 20
        const count = state.count || 0
        return Math.ceil(count / limit)
      },
    },
    mutations: {
      resetState (state) {
        Object.assign(state, getDefaultState())
      },
      setResponse (state, data) {
        state.list = data.subscribeScheduleList
        state.count = data.totalCount
      },
      setOriginFilter (state, filter) {
        state.originFilter = JSON.parse(JSON.stringify(filter))
      },
      setCounselContract (state, data) {
        state.isNonCounselContract = data.isNonCounselContract === 'Y'
      }
    },
    actions: {
      async getList ({ commit }, payload) {
        let response = null
        try {
          response = await this._vm.get(
            `${this._vm.SPARK_ONE_CREW_API_URL}subscribe/schedule`,
            payload,
          )
          commit('setResponse', response.data)
          commit('setOriginFilter', payload)
        } catch (error) {
          if (error.response) {
            response = error
          } else {
            throw error
          }
        }
        return response
      },
      async getGoodsData ({ commit }) {
        let response = null
        try {
          response = await this._vm.get(
            `${this._vm.SPARK_ONE_CREW_API_URL}subscribe/schedule/goodsList`,
          )
        } catch (error) {
          if (error.response) {
            response = error
          } else {
            throw error
          }
        }
        return response
      },
      async postInfo (context, payload) {
        let response = null
        try {
          response = await this._vm.post(
            `${this._vm.SPARK_ONE_CREW_API_URL}subscribe/schedule`,
            payload,
          )
        } catch (error) {
          if (error.response) {
            response = error
          } else {
            throw error
          }
        }
        return response
      },
    },
  }
