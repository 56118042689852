const getDefaultState = () => {
  return {
    list: null,
    count: 0,
    loginLogoutHistoryList: [],
    loginLogoutHistoryTotalCount: 0,
    originFilter: {},
    popupOriginFilter: {},
  }
}
export default {
  namespaced: true,
  state: getDefaultState(),
  getters: {
    totalPages: state => {
      const limit = state.originFilter?.limit || 50
      const count = state.count || 0
      return Math.ceil(count / limit)
    },
    popupTotalPages: state => {
      const limit = state.popupOriginFilter?.limit || 50
      const count = state.loginLogoutHistoryTotalCount || 0
      return Math.ceil(count / limit)
    },
  },
  mutations: {
    resetState (state) {
      Object.assign(state, getDefaultState())
    },
    setResponse (state, data) {
      state.list = data.crewList
      state.count = data.totalCount
    },
    setPopupResponse (state, data) {
      state.loginLogoutHistoryList = data.loginLogoutHistoryList
      state.loginLogoutHistoryTotalCount = data.loginLogoutHistoryTotalCount
    },
    setOriginFilter (state, filter) {
      state.originFilter = JSON.parse(JSON.stringify(filter))
    },
    setPopupOriginFilter (state, filter) {
      state.popupOriginFilter = JSON.parse(JSON.stringify(filter))
    },
  },
  actions: {
    // 운영자 조회
    async getList ({ commit }, payload) {
      let response = null
      try {
        response = await this._vm.get(
          `${this._vm.SPARK_ONE_CREW_API_URL}crew`,
          payload,
        )
        commit('setResponse', response.data)
        commit('setOriginFilter', payload)
      } catch (error) {
        if (error.response) {
          response = error
        } else {
          throw error
        }
      }
      return response
    },
    // 운영자 등록
    async postCrew (context, payload) {
      let response = null
      try {
        response = await this._vm.post(
          `${this._vm.SPARK_ONE_CREW_API_URL}crew`,
          payload,
        )
      } catch (error) {
        if (error.response) {
          response = error
        } else {
          throw error
        }
      }
      return response
    },
    // 운영자 수정
    async putCrew (context, payload) {
      let response = null
      try {
        response = await this._vm.put(
          `${this._vm.SPARK_ONE_CREW_API_URL}crew`,
          payload,
        )
      } catch (error) {
        if (error.response) {
          response = error
        } else {
          throw error
        }
      }
      return response
    },
    async getCrewInformationInfo ({ commit }, payload) {
      let response = null
      try {
        response = await this._vm.get(
          `${this._vm.SPARK_ONE_CREW_API_URL}crew/information/${payload.adminId}`,
          {},
        )
        // commit('setResponsePermission', response.data)
        commit('setOriginFilter', payload)
      } catch (error) {
        if (error.response) {
          response = error
        } else {
          throw error
        }
      }
      return response
    },
    // 접속이력조회
    async getLoginLogoutHistoryList ({ commit }, payload) {
      let response = null
      try {
        response = await this._vm.get(
          `${this._vm.SPARK_ONE_CREW_API_URL}crew/login-logout-history`,
          payload,
        )
        commit('setPopupResponse', response.data)
        commit('setPopupOriginFilter', payload)
      } catch (error) {
        if (error.response) {
          response = error
        } else {
          throw error
        }
      }
      return response
    },
  },
}
