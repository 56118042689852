const getDefaultState = () => {
    return {
        list: null,
        count: 0,
        originFilter: {},
    }
}
export default {
    namespaced: true,
    state: getDefaultState(),
    getters: {
        totalPages: state => {
            const limit = state.originFilter?.limit || 20
            const count = state.count || 0
            return Math.ceil(count / limit)
        },
    },
    mutations: {
        resetState (state) {
            Object.assign(state, getDefaultState())
        },
        setResponse (state, data) {
            state.list = data.salesList
            state.count = data.totalCount
        },
        setOriginFilter (state, filter) {
            state.originFilter = JSON.parse(JSON.stringify(filter))
        },
    },
    actions: {
        async getSalesList ({ commit }, payload) {
            let response = null
            try {
                response = await this._vm.get(
                    `${this._vm.SPARK_ONE_CREW_API_URL}sales`,
                    payload,
                )
                commit('setResponse', response.data)
                commit('setOriginFilter', payload)
            } catch (error) {
                if (error.response) {
                    response = error
                } else {
                    throw error
                }
            }
            return response
        },
        async getSalesExcelList (_context, payload) {
            let response = null
            try {
                response = await this._vm.get(
                    `${this._vm.SPARK_ONE_CREW_API_URL}sales/excel`,
                    payload,
                )
            } catch (error) {
                if (error.response) {
                    response = error
                } else {
                    throw error
                }
            }
            return response
        },
        async getSalesHometaxList (_context, payload) {
            let response = null
            try {
                response = await this._vm.get(
                    `${this._vm.SPARK_ONE_CREW_API_URL}sales/excel-hometax`,
                    payload,
                )
            } catch (error) {
                if (error.response) {
                    response = error
                } else {
                    throw error
                }
            }
            return response
        },


        //매출 수정 마감
        async putSalesCloseChange (_context, payload) {
            let response = null
            try {
                response = await this._vm.put(
                    `${this._vm.SPARK_ONE_CREW_API_URL}sales/close-change`,
                    payload,
                )
            } catch (error) {
                if (error.response) {
                    response = error
                } else {
                    throw error
                }
            }
            return response
        },



        //매출 수동 등록
        async postSalesRegist (_context, payload) {
            let response = null
            try {
                response = await this._vm.post(
                    `${this._vm.SPARK_ONE_CREW_API_URL}sales/regist`,
                    payload,
                )
            } catch (error) {
                if (error.response) {
                    response = error
                } else {
                    throw error
                }
            }
            return response
        },


        //매출 수정 가능 여부 확인
        async getSalesChangeCheck (_context, payload) {
            let response = null
            try {
                response = await this._vm.get(
                    `${this._vm.SPARK_ONE_CREW_API_URL}sales/${payload.salesId}/can-change`,
                    payload.data,
                )
            } catch (error) {
                if (error.response) {
                    response = error
                } else {
                    throw error
                }
            }
            return response
        },


        //매출 상세 조회
        async getSalesDetail(_context, payload) {
            let response = null
            try {
                response = await this._vm.get(
                    `${this._vm.SPARK_ONE_CREW_API_URL}sales/${payload.salesId}`,
                    payload.data,
                )
            } catch (error) {
                if (error.response) {
                    response = error
                } else {
                    throw error
                }
            }
            return response
        },




        //매출 수정
        async putSalesModify (_context, payload) {
            let response = null
            try {
                response = await this._vm.put(
                    `${this._vm.SPARK_ONE_CREW_API_URL}sales/${payload.salesId}`,
                    payload.data,
                )
            } catch (error) {
                if (error.response) {
                    response = error
                } else {
                    throw error
                }
            }
            return response
        },






    }
}